<h1 mat-dialog-title>Pesquise um dispositivo para testar a notificação.</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Usuários</mat-label>
        <mat-select name="playerId" [(ngModel)]="playerId" required>
            <mat-option *ngFor="let u of usuarios; let i = index;" [value]="u.playerId">
                {{u.nome}} {{u.sobrenome}} ({{u.nick}})
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancelar</button>
    <button mat-button cdkFocusInitial (click)="enviarManual()">Confirmar</button>
</div>