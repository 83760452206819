<section class="page">
  <div class="container">
    <div class="page-wrapper">
      <div class="page-header">
        <h1 class="page-title">Adicionar prêmio</h1>
        <div class="page-header__buttons">
          <button mat-stroked-button color="primary" routerLink="/premios">
            VOLTAR
          </button>
        </div>
      </div>
      <form class="page-form" (ngSubmit)="submit(form)" #form="ngForm">
        <h3 class="page-form__title">Dados do prêmio</h3>

        <div class="page-form-control">
          <label for="descricao">Enviado</label>
          <mat-checkbox
            (change)="onEnviadoChange($event)"
            [checked]="data.enviado == 'S' ? true : false"
          >
            Enviado</mat-checkbox
          >
        </div>

        <mat-form-field class="page-form-control half">
          <input
            matInput
            placeholder="Prêmio"
            [(ngModel)]="data.premio"
            name="premio"
            required
          />
        </mat-form-field>

        <mat-form-field class="page-form-control half">
          <mat-label>Jogo</mat-label>
          <mat-select name="jogo" [(ngModel)]="data.jogo" required>
            <mat-option *ngFor="let j of jogos" [value]="j">
              {{ j.nome }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="page-form-control">
          <label for="observacao">Observação</label>
          <ckeditor
            [editor]="helper.editor"
            [(ngModel)]="data.observacao"
            data="<p></p>"
            name="observacao"
            required
          ></ckeditor>
        </div>

        <mat-form-field class="page-form-control half">
          <input
            matInput
            [matDatepicker]="dataPicker"
            name="data"
            [(ngModel)]="data.data"
            placeholder="Escolha a data do sorteio"
            required
            disabled
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dataPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #dataPicker disabled="false"></mat-datepicker>
        </mat-form-field>

        <hr />

        <h3 class="page-form__title">Imagem prêmio (AUTOx280)</h3>

        <div class="page-form-control page-form-image">
          <img
            [src]="data.imagemPremio || 'assets/image-placeholder.png'"
            alt="imagemPremio"
          />
          <button type="button" mat-button (click)="fileInput.click()">
            Escolher arquivo
          </button>
          <input
            hidden
            (change)="onFileSelected($event.target.files)"
            #fileInput
            type="file"
            id="imagemPremio"
            accept="image/*"
          />
          <button
            class="page-form-image__button"
            (click)="removeImage()"
            type="button"
          >
            <i class="fas fa-trash"></i>
          </button>
        </div>

        <hr />

        <button mat-raised-button color="primary" type="submit">SALVAR</button>
      </form>
    </div>
  </div>
</section>
