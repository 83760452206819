<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Sobre
                </h1>
            </div>
            <mat-tab-group (selectedTabChange)="selectedTabChange($event)" animationDuration="0ms" #tab>
                <mat-tab label="Informações">
                    <form class="page-form" (ngSubmit)="alterarSobre(form)" #form="ngForm">

                        <mat-progress-bar mode="indeterminate" class="page-form-loading" *ngIf="loading">
                        </mat-progress-bar>
                        <div class="page-form-loading-shade" *ngIf="loading"></div>


                        <h3 class="page-form__title">
                            Dados do sobre
                        </h3>

                        <mat-form-field class="page-form-control">
                            <input matInput placeholder="Título" [(ngModel)]="sobre.titulo" name="titulo" required>
                        </mat-form-field>

                        <div class="page-form-control">
                            <label for="descricao1">Primeira descrição*</label>
                            <ckeditor [editor]="helper.editor" [(ngModel)]="sobre.descricao1" data="<p></p>"
                                name="descricao1" required></ckeditor>
                        </div>

                        <div class="page-form-control">
                            <label for="missao">Missão*</label>
                            <ckeditor [editor]="helper.editor" [(ngModel)]="sobre.missao" data="<p></p>" name="missao"
                                required></ckeditor>
                        </div>

                        <div class="page-form-control">
                            <label for="descricao2">Segunda descrição*</label>
                            <ckeditor [editor]="helper.editor" [(ngModel)]="sobre.descricao2" data="<p></p>"
                                name="descricao2" required></ckeditor>
                        </div>

                        <hr>

                        <mat-form-field class="page-form-control half">
                            <input matInput type="text" placeholder="Primeira característica (descrição)"
                                [(ngModel)]="sobre.caracteristica1" name="caracteristica1" required>
                        </mat-form-field>

                        <mat-form-field class="page-form-control half">
                            <input matInput type="tel" mask="0*" placeholder="Primeira característica (valor)"
                                [(ngModel)]="sobre.valorCaracteristica1" name="valorCaracteristica1" required>
                        </mat-form-field>

                        <mat-form-field class="page-form-control half">
                            <input matInput type="text" placeholder="Segunda característica (descrição)"
                                [(ngModel)]="sobre.caracteristica2" name="caracteristica2" required>
                        </mat-form-field>

                        <mat-form-field class="page-form-control half">
                            <input matInput type="tel" mask="0*" placeholder="Segunda característica (valor)"
                                [(ngModel)]="sobre.valorCaracteristica2" name="valorCaracteristica2" required>
                        </mat-form-field>

                        <mat-form-field class="page-form-control half">
                            <input matInput type="text" placeholder="Terceira característica (descrição)"
                                [(ngModel)]="sobre.caracteristica3" name="caracteristica3" required>
                        </mat-form-field>

                        <mat-form-field class="page-form-control half">
                            <input matInput type="tel" mask="0*" placeholder="Terceira característica (valor)"
                                [(ngModel)]="sobre.valorCaracteristica3" name="valorCaracteristica3" required>
                        </mat-form-field>

                        <hr>

                        <h3 class="page-form__title">
                            Imagem pequena (900x600)
                        </h3>

                        <div class="page-form-control page-form-image">
                            <img [src]="sobre.imagemPequena || 'assets/image-placeholder.png'" alt="imagemPequena">
                            <button type="button" mat-button (click)="fileImagemPequenaInput.click()">Escolher
                                arquivo</button>
                            <input hidden (change)="onFileImagemPequenaSelected($event.target.files)"
                                #fileImagemPequenaInput type="file" id="imagemPequena" accept="image/*">
                            <button class="page-form-image__button" (click)="removeImagePequena()" type="button">
                                <i class="fas fa-trash"></i>
                            </button>
                        </div>

                        <h3 class="page-form__title">
                            Imagem grande (1920x500)
                        </h3>

                        <div class="page-form-control page-form-image">
                            <img [src]="sobre.imagemGrande || 'assets/image-placeholder.png'" alt="imagemGrande">
                            <button type="button" mat-button (click)="fileImagemGrandeInput.click()">Escolher
                                arquivo</button>
                            <input hidden (change)="onFileImagemGrandeSelected($event.target.files)"
                                #fileImagemGrandeInput type="file" id="imagemGrande" accept="image/*">
                            <button class="page-form-image__button" (click)="removeImageGrande()" type="button">
                                <i class="fas fa-trash"></i>
                            </button>
                        </div>

                        <hr>

                        <button mat-raised-button color="primary" type="submit">
                            SALVAR
                        </button>

                    </form>
                </mat-tab>
                <mat-tab label="Linha do tempo">
                    <app-linha-do-tempo></app-linha-do-tempo>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</section>