<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Cabeçalhos
                </h1>
            </div>
            <form class="page-form" (ngSubmit)="alterarCabecalhos()">


                <div class="page-form-control half page-form-image">
                    <h3 class="page-form__title">
                        Sobre (1920x500)
                    </h3>
                    <img [src]="cabecalho.imagemSobre || 'assets/image-placeholder.png'" alt="imagemSobre">
                    <button type="button" mat-button (click)="fileImagemSobreInput.click()">Escolher
                        arquivo</button>
                    <input hidden (change)="onFileImagemSobreSelected($event.target.files)" #fileImagemSobreInput
                        type="file" id="imagemSobre" accept="image/*">
                    <button class="page-form-image__button" (click)="removeImageSobre()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>


                <div class="page-form-control half page-form-image">
                    <h3 class="page-form__title">
                        Notícias (1920x500)
                    </h3>
                    <img [src]="cabecalho.imagemNoticia || 'assets/image-placeholder.png'" alt="imagemNoticia">
                    <button type="button" mat-button (click)="fileImagemNoticiaInput.click()">Escolher
                        arquivo</button>
                    <input hidden (change)="onFileImagemNoticiaSelected($event.target.files)" #fileImagemNoticiaInput
                        type="file" id="imagemNoticia" accept="image/*">
                    <button class="page-form-image__button" (click)="removeImageNoticia()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>


                <div class="page-form-control half page-form-image">
                    <h3 class="page-form__title">
                        Times (1920x500)
                    </h3>
                    <img [src]="cabecalho.imagemNossosTimes || 'assets/image-placeholder.png'" alt="imagemNossosTimes">
                    <button type="button" mat-button (click)="fileImagemNossosTimesInput.click()">Escolher
                        arquivo</button>
                    <input hidden (change)="onFileImagemNossosTimesSelected($event.target.files)" #fileImagemNossosTimesInput
                        type="file" id="imagemNossosTimes" accept="image/*">
                    <button class="page-form-image__button" (click)="removeImageNossosTimes()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>


                <div class="page-form-control half page-form-image">
                    <h3 class="page-form__title">
                        Patrocinadores (1920x500)
                    </h3>
                    <img [src]="cabecalho.imagemPatrocinadores || 'assets/image-placeholder.png'"
                        alt="imagemPatrocinadores">
                    <button type="button" mat-button (click)="fileImagemPatrocinadoresInput.click()">Escolher
                        arquivo</button>
                    <input hidden (change)="onFileImagemPatrocinadoresSelected($event.target.files)"
                        #fileImagemPatrocinadoresInput type="file" id="imagemPatrocinadores" accept="image/*">
                    <button class="page-form-image__button" (click)="removeImagePatrocinadores()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>


                <div class="page-form-control half page-form-image">
                    <h3 class="page-form__title">
                        Agenda (1920x500)
                    </h3>
                    <img [src]="cabecalho.imagemAgenda || 'assets/image-placeholder.png'" alt="imagemAgenda">
                    <button type="button" mat-button (click)="fileImagemAgendaInput.click()">Escolher
                        arquivo</button>
                    <input hidden (change)="onFileImagemAgendaSelected($event.target.files)" #fileImagemAgendaInput
                        type="file" id="imagemAgenda" accept="image/*">
                    <button class="page-form-image__button" (click)="removeImageAgenda()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>


                <div class="page-form-control half page-form-image">
                    <h3 class="page-form__title">
                        Assista (1920x500)
                    </h3>
                    <img [src]="cabecalho.imagemAssista || 'assets/image-placeholder.png'" alt="imagemAssista">
                    <button type="button" mat-button (click)="fileImagemAssistaInput.click()">Escolher
                        arquivo</button>
                    <input hidden (change)="onFileImagemAssistaSelected($event.target.files)" #fileImagemAssistaInput
                        type="file" id="imagemAssista" accept="image/*">
                    <button class="page-form-image__button" (click)="removeImageAssista()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>


                <div class="page-form-control half page-form-image">
                    <h3 class="page-form__title">
                        Comunidade (1920x500)
                    </h3>
                    <img [src]="cabecalho.imagemComunidade || 'assets/image-placeholder.png'" alt="imagemComunidade">
                    <button type="button" mat-button (click)="fileImagemComunidadeInput.click()">Escolher
                        arquivo</button>
                    <input hidden (change)="onFileImagemComunidadeSelected($event.target.files)"
                        #fileImagemComunidadeInput type="file" id="imagemComunidade" accept="image/*">
                    <button class="page-form-image__button" (click)="removeImageComunidade()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>


                <div class="page-form-control half page-form-image">
                    <h3 class="page-form__title">
                        Contato (1920x500)
                    </h3>
                    <img [src]="cabecalho.imagemContato || 'assets/image-placeholder.png'" alt="imagemContato">
                    <button type="button" mat-button (click)="fileImagemContatoInput.click()">Escolher
                        arquivo</button>
                    <input hidden (change)="onFileImagemContatoSelected($event.target.files)" #fileImagemContatoInput
                        type="file" id="imagemContato" accept="image/*">
                    <button class="page-form-image__button" (click)="removeImageContato()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>

                <hr>

                <button mat-raised-button color="primary" type="submit">
                    SALVAR
                </button>

            </form>
        </div>
    </div>
</section>