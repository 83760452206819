import { Subscription } from 'rxjs';
import { LigaService } from './../../services/class/liga.service';
import { Liga } from './../../models/liga.model';
// default
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { JogoService, JogosApi } from 'src/app/services/class/jogo.service';
import { Jogo } from 'src/app/models/jogo.model';

@Component({
  selector: 'app-ligas-editar',
  templateUrl: './ligas-editar.component.html',
  styleUrls: ['./ligas-editar.component.scss']
})
export class LigasEditarComponent implements OnInit {

  liga: Liga = new Liga();

  file: File;
  progress: any;

  buscarLigaSubscription: Subscription;
  buscarJogosSubscription: Subscription;

  loading: boolean = false;
  jogos: Jogo[] = [];

  constructor(
    public helper: HelperService,
    public ligaService: LigaService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute,
    public jogoService: JogoService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.loading = true;
      this.buscarLigaSubscription = this.ligaService.getById(param.id)
        .subscribe((res: Liga) => {
          this.liga = res;
          this.loading = false;
        }, e => this.loading = false);
    })
    this.buscarJogos();
  }

  ngOnDestroy() {
    this.buscarJogosSubscription.unsubscribe();
  }

  buscarJogos() {
    this.buscarJogosSubscription = this.jogoService.get(-99, -99, '1', 'ASC')
      .subscribe((res: JogosApi) => this.jogos = res.jogos);
  }

  alterarLiga(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando liga...');

    this.ligaService.patch(this.liga)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/liga/imagem?id=${this.liga.id}`);
        this.router.navigate(['/ligas']).then(() => {
          this.helper.openSnackBar('Liga alterada com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.liga.situacao = 'A' : this.liga.situacao = 'I';
  }

  onCartolaChange(event: MatCheckboxChange) {
    event.checked ? this.liga.cartola = 'S' : this.liga.cartola = 'N';
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.file, '/liga/imagem?id=-99').then((res: any) => {
      this.liga.imagem = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.ligaService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImage() {
    this.liga.imagem = '';
  }

}
