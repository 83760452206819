import { Avatar } from 'src/app/models/avatar.model';
import { AvatarService } from 'src/app/services/class/avatar.service';
// default
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-avatars-editar',
  templateUrl: './avatars-editar.component.html',
  styleUrls: ['./avatars-editar.component.scss']
})
export class AvatarsEditarComponent implements OnInit {

  avatar: Avatar = new Avatar();
  buscarSubscription: Subscription;

  file: File;
  progress: any;

  constructor(
    public helper: HelperService,
    public avatarService: AvatarService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => this.buscar(param.id));
  }

  buscar(id: number) {
    this.buscarSubscription = this.avatarService.getById(id)
      .subscribe((res: Avatar) => this.avatar = res);
  }

  alterarAvatar(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando avatar...');

    this.avatarService.patch(this.avatar)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/avatar/imagem?id=${this.avatar.id}`);
        this.router.navigate(['/avatares']).then(() => {
          this.helper.openSnackBar('Avatar alterado com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.avatar.situacao = 'A' : this.avatar.situacao = 'I';
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('Alterando imagem...');
    this.submitImages(this.file, '/avatar/imagem?id=-99').then((res: any) => {
      this.avatar.imagem = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.avatarService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImage() {
    this.avatar.imagem = '';
  }

}
