<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Editar banner
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" routerLink="/banners">
                        VOLTAR
                    </button>
                </div>
            </div>
            <form class="page-form" (ngSubmit)="alterarBanner(form)" #form="ngForm">

                <mat-progress-bar mode="indeterminate" class="page-form-loading" *ngIf="loading">
                </mat-progress-bar>
                <div class="page-form-loading-shade" *ngIf="loading"></div>

                <h3 class="page-form__title">
                    Dados do banner
                </h3>

                <div class="page-form-control">
                    <label for="titulo">Título</label>
                    <ckeditor [editor]="helper.editor" [(ngModel)]="banner.titulo" data="<p></p>" name="titulo"></ckeditor>
                </div>

                <mat-form-field class="page-form-control">
                    <input matInput placeholder="Link" [pattern]="helper.urlRegex" [(ngModel)]="banner.link"
                        name="link">
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <input matInput placeholder="Ordem" [(ngModel)]="banner.ordem" name="ordem" required>
                </mat-form-field>

                <hr>

                <h3 class="page-form__title">
                    Imagem (1920x800)
                </h3>

                <div class="page-form-control page-form-image">
                    <img [src]="banner.imagem || 'assets/image-placeholder.png'" alt="imagem">
                    <button type="button" mat-button (click)="fileInput.click()">Escolher arquivo</button>
                    <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" id="imagem"
                        accept="image/*">
                    <button class="page-form-image__button" (click)="removeImage()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>

                <div class="page-form-control">
                    <mat-slide-toggle (change)="onSituacaoChange($event)"
                        [checked]="banner.situacao == 'A' ? true : false">Situação:
                        {{banner.situacao == 'A' ? 'Ativo' : 'Inativo'}}</mat-slide-toggle>
                </div>

                <hr>

                <button mat-raised-button color="primary" type="submit">
                    SALVAR
                </button>

            </form>
        </div>
    </div>
</section>