<section class="page">
  <div class="container">
    <div class="page-wrapper">
      <div class="page-header">
        <h1 class="page-title">Editar notícia</h1>
        <div class="page-header__buttons">
          <button mat-raised-button color="primary" (click)="abrirLink(form)">
            VER PREVIEW
          </button>
          <button mat-stroked-button color="primary" routerLink="/noticias">
            VOLTAR
          </button>
        </div>
      </div>
      <form class="page-form" (ngSubmit)="alterarNoticia(form)" #form="ngForm">
        <mat-progress-bar
          mode="indeterminate"
          class="page-form-loading"
          *ngIf="loading"
        >
        </mat-progress-bar>
        <div class="page-form-loading-shade" *ngIf="loading"></div>

        <h3 class="page-form__title">Dados da notícia</h3>

        <div class="page-form-control">
          <label for="descricao">Destaque*</label>
          <mat-checkbox
            (change)="onDestaqueChange($event)"
            [checked]="noticia.destaque == 'S' ? true : false"
            >Destaque</mat-checkbox
          >
        </div>

        <mat-form-field class="page-form-control">
          <input
            matInput
            placeholder="Título"
            [(ngModel)]="noticia.titulo"
            name="titulo"
            required
          />
        </mat-form-field>

        <mat-form-field class="page-form-control">
          <input
            matInput
            placeholder="Título da notificação"
            [(ngModel)]="noticia.tituloPush"
            name="tituloPush"
          />
        </mat-form-field>

        <mat-form-field class="page-form-control">
          <input
            matInput
            placeholder="Autor"
            [(ngModel)]="noticia.autor"
            name="autor"
          />
        </mat-form-field>

        <mat-form-field class="page-form-control half">
          <mat-label>Jogo</mat-label>
          <mat-select
            name="jogo"
            [(ngModel)]="noticia.jogo"
            [compareWith]="jogoService.compareFn"
            required
          >
            <mat-option *ngFor="let j of jogos" [value]="j">
              {{ j.nome }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="page-form-control half">
          <input
            matInput
            [matDatepicker]="dataPicker"
            name="data"
            [(ngModel)]="noticia.data"
            placeholder="Escolha a data"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dataPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker touchUi #dataPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="page-form-control">
          <textarea
            matInput
            rows="6"
            placeholder="Descrição"
            [(ngModel)]="noticia.descricaoSimples"
            name="descricao"
            required
          ></textarea>
        </mat-form-field>

        <div class="page-form-control">
          <label for="descricao">Descrição completa*</label>

          <ckeditor
            [editor]="helper.editor"
            [(ngModel)]="noticia.descricaoCompleta"
            data="<p></p>"
            name="descricaoCompleta"
            required
          ></ckeditor>
        </div>

        <hr />

        <h3 class="page-form__title">Imagem (1920x500)</h3>

        <div class="page-form-control page-form-image">
          <img
            [src]="noticia.imagem || 'assets/image-placeholder.png'"
            alt="imagem"
          />
          <button type="button" mat-button (click)="fileInput.click()">
            Escolher arquivo
          </button>
          <input
            hidden
            (change)="onFileSelected($event.target.files)"
            #fileInput
            type="file"
            id="imagem"
            accept="image/*"
          />
          <button
            class="page-form-image__button"
            (click)="removeImage()"
            type="button"
          >
            <i class="fas fa-trash"></i>
          </button>
        </div>

        <div class="page-form-control">
          <mat-slide-toggle
            (change)="onSituacaoChange($event)"
            [checked]="noticia.situacao == 'A' ? true : false"
            >Situação:
            {{
              noticia.situacao == "A" ? "Ativo" : "Inativo"
            }}</mat-slide-toggle
          >
        </div>

        <hr />

        <button mat-raised-button color="primary" type="submit">SALVAR</button>
      </form>
    </div>
  </div>
</section>
