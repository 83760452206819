<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Adicionar avatar
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" routerLink="/avatares">
                        VOLTAR
                    </button>
                </div>
            </div>
            <form class="page-form" (ngSubmit)="inserirAvatar(form)" #form="ngForm">

                <h3 class="page-form__title">
                    Dados do avatar
                </h3>

                <mat-form-field class="page-form-control">
                    <input matInput placeholder="Nome" [(ngModel)]="avatar.nome" name="nome" required>
                </mat-form-field>

                <hr>

                <h3 class="page-form__title">
                    Imagem (600x600)
                </h3>

                <div class="page-form-control page-form-image">
                    <img [src]="avatar.imagem || 'assets/image-placeholder.png'" alt="imagem">
                    <button type="button" mat-button (click)="fileInput.click()">Escolher arquivo</button>
                    <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" id="imagem"
                        accept="image/*">
                    <button class="page-form-image__button" (click)="removeImage()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>

                <div class="page-form-control">
                    <mat-slide-toggle (change)="onSituacaoChange($event)"
                        [checked]="avatar.situacao == 'A' ? true : false">Situação:
                        {{avatar.situacao == 'A' ? 'Ativo' : 'Inativo'}}</mat-slide-toggle>
                </div>

                <hr>

                <button mat-raised-button color="primary" type="submit">
                    SALVAR
                </button>

            </form>
        </div>
    </div>
</section>