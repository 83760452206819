import { NgModule } from '@angular/core';
// Material
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { getBrazilianPaginatorIntl } from './brazilian-paginator-intl';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
// import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
// imports
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from "ngx-currency";
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// components
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { LoadingComponent } from './components/loading/loading.component';
import { HeaderComponent } from './components/header/header.component';
import { ModalResetPasswordComponent } from './components/modal-reset-password/modal-reset-password.component';
import { ModalGaleriaComponent } from './components/modal-galeria/modal-galeria.component';
// pages
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { UsuariosEditarComponent } from './pages/usuarios-editar/usuarios-editar.component';
import { UsuariosCriarComponent } from './pages/usuarios-criar/usuarios-criar.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { ConfiguracoesComponent } from './pages/configuracoes/configuracoes.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { JogosComponent } from './pages/jogos/jogos.component';
import { JogosCriarComponent } from './pages/jogos-criar/jogos-criar.component';
import { JogosEditarComponent } from './pages/jogos-editar/jogos-editar.component';
import { TimesComponent } from './pages/times/times.component';
import { TimesCriarComponent } from './pages/times-criar/times-criar.component';
import { TimesEditarComponent } from './pages/times-editar/times-editar.component';
import { JogadoresComponent } from './pages/jogadores/jogadores.component';
import { JogadoresCriarComponent } from './pages/jogadores-criar/jogadores-criar.component';
import { JogadoresEditarComponent } from './pages/jogadores-editar/jogadores-editar.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { PartidasComponent } from './pages/partidas/partidas.component';
import { PartidasCriarComponent } from './pages/partidas-criar/partidas-criar.component';
import { PartidasEditarComponent } from './pages/partidas-editar/partidas-editar.component';
import { BannersComponent } from './pages/banners/banners.component';
import { BannersCriarComponent } from './pages/banners-criar/banners-criar.component';
import { BannersEditarComponent } from './pages/banners-editar/banners-editar.component';
import { ProdutosComponent } from './pages/produtos/produtos.component';
import { ProdutosCriarComponent } from './pages/produtos-criar/produtos-criar.component';
import { ProdutosEditarComponent } from './pages/produtos-editar/produtos-editar.component';
import { NoticiasComponent } from './pages/noticias/noticias.component';
import { NoticiasCriarComponent } from './pages/noticias-criar/noticias-criar.component';
import { NoticiasEditarComponent } from './pages/noticias-editar/noticias-editar.component';
import { PatrocinadoresComponent } from './pages/patrocinadores/patrocinadores.component';
import { PatrocinadoresCriarComponent } from './pages/patrocinadores-criar/patrocinadores-criar.component';
import { PatrocinadoresEditarComponent } from './pages/patrocinadores-editar/patrocinadores-editar.component';
import { EquipeComponent } from './pages/equipe/equipe.component';
import { EquipeCriarComponent } from './pages/equipe-criar/equipe-criar.component';
import { EquipeEditarComponent } from './pages/equipe-editar/equipe-editar.component';
import { LinhaDoTempoComponent } from './pages/linha-do-tempo/linha-do-tempo.component';
import { LinhaDoTempoCriarComponent } from './pages/linha-do-tempo-criar/linha-do-tempo-criar.component';
import { LinhaDoTempoEditarComponent } from './pages/linha-do-tempo-editar/linha-do-tempo-editar.component';
import { MensagensComponent } from './pages/mensagens/mensagens.component';
import { ComunidadeComponent } from './pages/comunidade/comunidade.component';
import { ComunidadeCriarComponent } from './pages/comunidade-criar/comunidade-criar.component';
import { ComunidadeEditarComponent } from './pages/comunidade-editar/comunidade-editar.component';
import { LigasComponent } from './pages/ligas/ligas.component';
import { LigasCriarComponent } from './pages/ligas-criar/ligas-criar.component';
import { LigasEditarComponent } from './pages/ligas-editar/ligas-editar.component';
import { LivesComponent } from './pages/lives/lives.component';
import { LivesCriarComponent } from './pages/lives-criar/lives-criar.component';
import { LivesEditarComponent } from './pages/lives-editar/lives-editar.component';
// locale
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { JogosConfiguracaoComponent } from './pages/jogos-configuracao/jogos-configuracao.component';
import { JogadoresConfiguracaoComponent } from './pages/jogadores-configuracao/jogadores-configuracao.component';
import { ApoiadoresComponent } from './pages/apoiadores/apoiadores.component';
import { ApoiadoresCriarComponent } from './pages/apoiadores-criar/apoiadores-criar.component';
import { ApoiadoresEditarComponent } from './pages/apoiadores-editar/apoiadores-editar.component';
import { CabecalhosComponent } from './pages/cabecalhos/cabecalhos.component';
import { SorteiosComponent } from './pages/sorteios/sorteios.component';
import { SorteiosCriarComponent } from './pages/sorteios-criar/sorteios-criar.component';
import { SorteiosEditarComponent } from './pages/sorteios-editar/sorteios-editar.component';
import { NotificacoesComponent } from './pages/notificacoes/notificacoes.component';
import { NotificacoesCriarComponent } from './pages/notificacoes-criar/notificacoes-criar.component';
import { NotificacoesEditarComponent } from './pages/notificacoes-editar/notificacoes-editar.component';
import { ModalNotificacaoComponent } from './components/modal-notificacao/modal-notificacao.component';
import { PerguntasComponent } from './pages/perguntas/perguntas.component';
import { PerguntasCriarComponent } from './pages/perguntas-criar/perguntas-criar.component';
import { PerguntasEditarComponent } from './pages/perguntas-editar/perguntas-editar.component';
import { RespostasComponent } from './pages/respostas/respostas.component';
import { ModalEditarRespostaComponent } from './components/modal-editar-resposta/modal-editar-resposta.component';
import { CartolaComponent } from './pages/cartola/cartola.component';
import { ModalCartolaComponent } from './components/modal-cartola/modal-cartola.component';
import { AvatarsComponent } from './pages/avatars/avatars.component';
import { AvatarsCriarComponent } from './pages/avatars-criar/avatars-criar.component';
import { AvatarsEditarComponent } from './pages/avatars-editar/avatars-editar.component';
import { FilterPipe } from './filter.pipe';
registerLocaleData(localePt);

export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true
};

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        LoginComponent,
        ModalResetPasswordComponent,
        DialogComponent,
        LoadingComponent,
        ModalGaleriaComponent,
        // pages
        UsuariosComponent,
        UsuariosCriarComponent,
        UsuariosEditarComponent,
        ConfiguracoesComponent,
        ContatoComponent,
        JogosComponent,
        JogosCriarComponent,
        JogosEditarComponent,
        TimesComponent,
        TimesCriarComponent,
        TimesEditarComponent,
        JogadoresComponent,
        JogadoresCriarComponent,
        JogadoresEditarComponent,
        SobreComponent,
        PartidasComponent,
        PartidasCriarComponent,
        PartidasEditarComponent,
        BannersComponent,
        BannersCriarComponent,
        BannersEditarComponent,
        ProdutosComponent,
        ProdutosCriarComponent,
        ProdutosEditarComponent,
        NoticiasComponent,
        NoticiasCriarComponent,
        NoticiasEditarComponent,
        PatrocinadoresComponent,
        PatrocinadoresCriarComponent,
        PatrocinadoresEditarComponent,
        EquipeComponent,
        EquipeCriarComponent,
        EquipeEditarComponent,
        LinhaDoTempoComponent,
        LinhaDoTempoCriarComponent,
        LinhaDoTempoEditarComponent,
        MensagensComponent,
        ComunidadeComponent,
        ComunidadeCriarComponent,
        ComunidadeEditarComponent,
        LigasComponent,
        LigasCriarComponent,
        LigasEditarComponent,
        LivesComponent,
        LivesCriarComponent,
        LivesEditarComponent,
        JogosConfiguracaoComponent,
        JogadoresConfiguracaoComponent,
        ApoiadoresComponent,
        ApoiadoresCriarComponent,
        ApoiadoresEditarComponent,
        CabecalhosComponent,
        SorteiosComponent,
        SorteiosCriarComponent,
        SorteiosEditarComponent,
        NotificacoesComponent,
        NotificacoesCriarComponent,
        NotificacoesEditarComponent,
        ModalNotificacaoComponent,
        PerguntasComponent,
        PerguntasCriarComponent,
        PerguntasEditarComponent,
        RespostasComponent,
        ModalEditarRespostaComponent,
        CartolaComponent,
        ModalCartolaComponent,
        AvatarsComponent,
        AvatarsCriarComponent,
        AvatarsEditarComponent,
        FilterPipe,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        CKEditorModule,
        NgxMaskModule.forRoot(),
        NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
        NgxDropzoneModule,
        NgxMatSelectSearchModule,
        // Material
        MatInputModule,
        MatFormFieldModule,
        MatSnackBarModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatDialogModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatRadioModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTabsModule,
        MatTreeModule,
        MatButtonToggleModule
    ],
    providers: [
        { provide: MatPaginatorIntl, useValue: getBrazilianPaginatorIntl() },
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        { provide: LOCALE_ID, useValue: 'pt-BR' },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
