<section class="page">
    <div class="container">
        <h1 class="home-title">
            Seja bem-vindo, {{auth.user.nome}} {{auth.user.sobrenome}}!
        </h1>
        <div class="home-boxes">
            <button class="home-box" mat-stroked-button routerLink="/apoiadores">
                apoiadores
            </button>
            <button class="home-box" mat-stroked-button routerLink="/avatares">
                avatares
            </button>
            <button class="home-box" mat-stroked-button routerLink="/banners">
                banners
            </button>
            <button class="home-box" mat-stroked-button routerLink="/cabecalhos">
                cabeçalhos
            </button>
            <button class="home-box" mat-stroked-button routerLink="/comunidade">
                comunidade
            </button>
            <button class="home-box" mat-stroked-button routerLink="/configuracoes">
                configurações
            </button>
            <button class="home-box" mat-stroked-button routerLink="/contato">
                contato
            </button>
            <button class="home-box" mat-stroked-button routerLink="/equipe">
                equipe
            </button>
            <button class="home-box" mat-stroked-button routerLink="/jogadores">
                jogadores
            </button>
            <button class="home-box" mat-stroked-button routerLink="/jogos">
                jogos
            </button>
            <button class="home-box" mat-stroked-button routerLink="/ligas">
                ligas
            </button>
            <button class="home-box" mat-stroked-button routerLink="/lives">
                lives
            </button>
            <button class="home-box" mat-stroked-button routerLink="/mensagens">
                mensagens
            </button>
            <button class="home-box" mat-stroked-button routerLink="/noticias">
                notícias
            </button>
            <button class="home-box" mat-stroked-button routerLink="/notificacoes">
                notificações
            </button>
            <button class="home-box" mat-stroked-button routerLink="/palpitei">
                palpitei
            </button>
            <button class="home-box" mat-stroked-button routerLink="/partidas">
                partidas
            </button>
            <button class="home-box" mat-stroked-button routerLink="/patrocinadores">
                patrocinadores
            </button>
            <button class="home-box" mat-stroked-button routerLink="/perguntas">
                perguntas
            </button>
            <button class="home-box" mat-stroked-button routerLink="/produtos">
                produtos
            </button>
            <button class="home-box" mat-stroked-button routerLink="/sobre/0">
                sobre
            </button>
            <button class="home-box" mat-stroked-button routerLink="/premios">
                prêmios
            </button>
            <button class="home-box" mat-stroked-button routerLink="/times">
                times
            </button>
            <button class="home-box" mat-stroked-button routerLink="/usuarios">
                usuários
            </button>

        </div>
    </div>
</section>