import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { RespostaService, RespostasApi } from 'src/app/services/class/resposta.service';
import { Resposta } from 'src/app/models/resposta.model';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { LoadingService } from 'src/app/services/loading.service';
import { HelperService } from 'src/app/services/helper.service';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { ModalEditarRespostaComponent } from 'src/app/components/modal-editar-resposta/modal-editar-resposta.component';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-respostas',
  templateUrl: './respostas.component.html',
  styleUrls: ['./respostas.component.scss']
})
export class RespostasComponent implements OnInit, AfterViewInit {

  @Input('idPergunta') idPergunta: number;

  displayedColumns: string[] = ['select', '1', '2', 'actions'];
  // imagem, descricao, situacao

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  data: Resposta[] = [];
  buscarSubscription: Subscription;

  search: string;
  filterSubscription: Subscription;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selection = new SelectionModel<Resposta>(true, []);

  constructor(
    public respostaService: RespostaService,
    public loadingService: LoadingService,
    public helper: HelperService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.buscarSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.buscar();
  }

  buscar() {
    this.buscarSubscription = merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.respostaService.get(this.paginator.pageIndex + 1, this.paginator.pageSize, this.sort.active, this.sort.direction.toLocaleUpperCase(), this.idPergunta, this.search);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.selection.clear();
          this.resultsLength = data.numeroPaginas * this.paginator.pageSize;
          return data.respostas;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          this.selection.clear();
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  deletarData(resposta: Resposta) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: {
        title: 'Excluir resposta',
        description: 'Você realmente quer excluir essa respota? Esse processe não pode ser desfeito.'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.present('Excluindo resposta...');
        this.respostaService.delete(resposta).subscribe((res: any) => {
          this.helper.openSnackBar('Resposta removido com sucesso.');
          this.loadingService.dismiss();
          this.buscar();
        }, e => this.loadingService.dismiss());
      }
    })
  }

  deletarDatas() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: {
        title: 'Excluir respostas',
        description: 'Você realmente quer excluir essas respostas? Esse processe não pode ser desfeito.'
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.respostaService.deleteSelected(this.selection.selected)
          .subscribe((res: any) => {
            this.loadingService.present('Excluindo respostas');
            this.helper.openSnackBar('Respostas removidos com sucesso.');
            this.loadingService.dismiss();
            this.buscar();
          }, e => this.loadingService.dismiss());
      }
    })
  }

  filter(e) {
    if (this.paginator.pageIndex > 1) {
      this.paginator.pageIndex = 0;
    }
    if (e) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.respostaService.get(this.paginator.pageIndex + 1, this.paginator.pageSize, this.sort.active, this.sort.direction.toLocaleUpperCase(), this.idPergunta, e.toLocaleLowerCase())
        .subscribe((res: RespostasApi) => {
          this.data = this.paginator.pageIndex == 0 ? res.respostas : this.data.concat(res.respostas);
        });
    } else {
      this.buscar();
    }
  }

  clearFilter() {
    this.search = '';
    this.buscar();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  @ViewChild('descricaoValue') descricaoValueInput: ElementRef;
  adicionarResposta(value: string) {
    this.loadingService.present('Adicionado...');
    let resposta = new Resposta();
    resposta.descricao = value;
    resposta.idPergunta = this.idPergunta;
    this.respostaService.post(resposta).subscribe(res => {
      this.helper.openSnackBar('Item adicionado com sucesso.');
      this.loadingService.dismiss();
      this.descricaoValueInput.nativeElement.value = '';
      this.buscar();
    }, e => this.loadingService.dismiss());
  }

  openDialog(resposta: Resposta) {
    const dialogRef = this.dialog.open(ModalEditarRespostaComponent, {
      width: '540px',
      data: resposta
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result)
        this.loadingService.present('Alterando...');
        this.respostaService.patch(result).subscribe(res => {
          this.helper.openSnackBar('Item alterado com sucesso.');
          this.loadingService.dismiss();
        }, e => this.loadingService.dismiss());
      }
    })
  }


}
