<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Editar produto
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" routerLink="/produtos">
                        VOLTAR
                    </button>
                </div>
            </div>
            <form class="page-form" (ngSubmit)="alterarProduto(form)" #form="ngForm">

                <mat-progress-bar mode="indeterminate" class="page-form-loading" *ngIf="loading">
                </mat-progress-bar>
                <div class="page-form-loading-shade" *ngIf="loading"></div>

                <h3 class="page-form__title">
                    Dados do produto
                </h3>

                <mat-form-field class="page-form-control">
                    <input matInput placeholder="Nome" [(ngModel)]="produto.nome" name="nome" required>
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <input matInput placeholder="Valor" type="tel" [(ngModel)]="produto.valor" currencyMask name="valor"
                        required>
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <input matInput placeholder="Link" [pattern]="helper.urlRegex" [(ngModel)]="produto.link"
                        name="link">
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <textarea matInput rows="6" placeholder="Descrição" [(ngModel)]="produto.descricao" name="descricao"
                        required></textarea>
                </mat-form-field>

                <hr>

                <h3 class="page-form__title">
                    Imagem (900x350)
                </h3>

                <div class="page-form-control page-form-image">
                    <img [src]="produto.imagem || 'assets/image-placeholder.png'" alt="imagem">
                    <button type="button" mat-button (click)="fileInput.click()">Escolher arquivo</button>
                    <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" id="imagem"
                        accept="image/*">
                    <button class="page-form-image__button" (click)="removeImage()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>

                <div class="page-form-control">
                    <mat-slide-toggle (change)="onSituacaoChange($event)"
                        [checked]="produto.situacao == 'A' ? true : false">Situação:
                        {{produto.situacao == 'A' ? 'Ativo' : 'Inativo'}}</mat-slide-toggle>
                </div>

                <hr>

                <button mat-raised-button color="primary" type="submit">
                    SALVAR
                </button>

            </form>
        </div>
    </div>
</section>