<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Configurações
                </h1>
            </div>
            <form class="page-form" (ngSubmit)="alterarCadastroGeral(form)" #form="ngForm">

                <h3 class="page-form__title">
                    Configurações do site
                </h3>

                <mat-form-field class="page-form-control">
                    <input matInput type="text" placeholder="Título" [(ngModel)]="cadastroGeral.titulo" name="titulo"
                        required>
                    <mat-hint>Irá aparecer no topo do navegador e nos mecanismos de busca.</mat-hint>
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <textarea matInput placeholder="Descrição" name="descricao" [(ngModel)]="cadastroGeral.descricao"
                        rows="6" required></textarea>
                    <mat-hint>Para mecanismos de busca e redes sociais. 0/160.</mat-hint>
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <input matInput type="text" placeholder="Palavras-chave" [(ngModel)]="cadastroGeral.palavrasChave"
                        name="palavrasChave" required>
                    <mat-hint>Usadas pelos mecanismos de busca para encontrar seu site. Utilize palavras separadas por
                        vírgula.</mat-hint>
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <input matInput type="text" placeholder="Cor do filtro" [(ngModel)]="cadastroGeral.cor" name="cor"
                        required>
                    <mat-hint>Cor que aparece no cabeçalho do chrome (mobile/watch). Digitar hexcode (Ex: #FFFFFF).</mat-hint>
                </mat-form-field>

                <button mat-raised-button color="primary" type="submit">
                    SALVAR
                </button>

            </form>
        </div>
    </div>
</section>