import { Liga } from './../../models/liga.model';
import { LigaService, LigasApi } from './../../services/class/liga.service';
import { Subscription } from 'rxjs';
import { JogoService, JogosApi } from './../../services/class/jogo.service';
import { Jogo } from './../../models/jogo.model';
import { PartidaService } from './../../services/class/partida.service';
import { Partida } from './../../models/partida.model';
// default
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Resposta } from 'src/app/models/resposta.model';
import { Pergunta } from 'src/app/models/pergunta.model';

@Component({
  selector: 'app-partidas-criar',
  templateUrl: './partidas-criar.component.html',
  styleUrls: ['./partidas-criar.component.scss']
})
export class PartidasCriarComponent implements OnInit {

  partida: Partida = new Partida();

  jogos: Jogo[] = [];
  ligas: Liga[] = [];
  perguntas: Pergunta[] = [];
  respostas: Resposta[] = [];

  data;
  hora;

  file: File;
  progress: any;

  buscarJogosSubscription: Subscription;
  buscarLigasSubscription: Subscription;

  constructor(
    public helper: HelperService,
    public partidaService: PartidaService,
    public jogoService: JogoService,
    public ligaService: LigaService,
    public loadingService: LoadingService,
    public router: Router
  ) {
    this.data = this.helper.moment().format('DD/MM/YYYY');
  }

  ngOnInit() {
    this.buscarJogosSubscription = this.jogoService.get(-99, -99, '1', 'ASC')
      .subscribe((res: JogosApi) => this.jogos = res.jogos);

    this.buscarLigasSubscription = this.ligaService.getAtivas()
      .subscribe((res: Liga[]) => this.ligas = res);
  }

  ngOnDestroy() {
    this.buscarJogosSubscription.unsubscribe();
    this.buscarLigasSubscription.unsubscribe();
  }

  inserirPartida(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    if (!this.partida.jogo.id) {
      this.helper.openSnackBar('Selecione um jogo, por favor.');
      return;
    }

    if (this.partida.liga.cartola === 'S' && !this.hora) {
      console.log(this.partida)
      this.helper.openSnackBar('Informe uma hora para a partida, por favor.');
      return;
    }

    this.loadingService.present('Inserindo partida...');
    this.partida.data = this.helper.moment(this.data, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.partida.hora = `${this.helper.moment().format('YYYY-MM-DD')} ${this.hora}:00`;
    this.partidaService.post(this.partida)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/partida/imagemEscudoAdversario?id=${res}`);
        this.router.navigate(['/partidas']).then(() => {
          this.helper.openSnackBar('Partida inserida com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.partida.situacao = 'A' : this.partida.situacao = 'I';
  }

  onAoVivoChange(event: MatCheckboxChange) {
    event.checked ? this.partida.aoVivo = 'S' : this.partida.aoVivo = 'N';
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.file, '/partida/imagemEscudoAdversario?id=-99').then((res: any) => {
      this.partida.imagemEscudoAdversario = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.partidaService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  onLigaChange(e: Liga) {
    this.partida.jogo = e.jogo;
  }

  removeImage() {
    this.partida.imagemEscudoAdversario = '';
  }

}
