<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Adicionar pergunta
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" routerLink="/perguntas">
                        VOLTAR
                    </button>
                </div>
            </div>
            <form class="page-form" (ngSubmit)="inserirPergunta(form)" #form="ngForm">

                <h3 class="page-form__title">
                    Dados da pergunta
                </h3>

                <mat-form-field class="page-form-control">
                    <textarea matInput placeholder="Descrição" name="descricao" [(ngModel)]="pergunta.descricao"
                        rows="6" required></textarea>
                </mat-form-field>

                <mat-form-field class="page-form-control third">
                    <mat-label>Jogo</mat-label>
                    <mat-select name="jogo" [(ngModel)]="pergunta.jogo" required>
                        <mat-option *ngFor="let j of jogos" [value]="j">
                            {{j.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- <h3 class="page-form__title">
                    Imagem (400x400)
                </h3>

                <div class="page-form-control page-form-image">
                    <img [src]="pergunta.imagem || 'assets/image-placeholder.png'" alt="imagem">
                    <button type="button" mat-button (click)="fileInput.click()">Escolher arquivo</button>
                    <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" id="imagem"
                        accept="image/*">
                    <button class="page-form-image__button" (click)="removeImage()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div> -->

                <div class="page-form-control">
                    <mat-slide-toggle (change)="onSituacaoChange($event)"
                        [checked]="pergunta.situacao == 'A' ? true : false">Situação:
                        {{pergunta.situacao == 'A' ? 'Ativo' : 'Inativo'}}</mat-slide-toggle>
                </div>

                <hr>

                <button mat-raised-button color="primary" type="submit">
                    CONTINUAR
                </button>

            </form>
        </div>
    </div>
</section>