import { HelperService } from './../../services/helper.service';
import { LoadingService } from './../../services/loading.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Caracteristica } from './../../models/caracteristica.model';
import { Equipamento } from './../../models/equipamento.model';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTree } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfiguracaoJogoService } from './../../services/class/configuracao-jogo.service';
import { JogoService } from './../../services/class/jogo.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { ConfiguracaoJogo } from './../../models/configuracao-jogo.model';
import { Component, OnInit, Injectable, ViewChild, ElementRef } from '@angular/core';

@Injectable()
export class listDatabase {
  dataChange = new BehaviorSubject<Equipamento[]>([]);

  get data(): Equipamento[] { return this.dataChange.value; }

  /** Add an item to to-do list */
  insertItem(parent: any, name: string) {
    if (parent.caracteristicas) {
      parent.caracteristicas.push({ id: 0, nome: name } as Caracteristica);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: any, name: string) {
    node.id = 0;
    node.nome = name;
    node.isEditing = false;
    this.dataChange.next(this.data);
  }

  removeItem(parent: any, index: number) {
    this.data[parent.index].caracteristicas.splice(index, 1);
    this.dataChange.next(this.data);
  }

  editItem(parent: any) {
    parent.isEditing = !parent.isEditing;
    if (parent.caracteristicas) {
      parent.caracteristicas.map(c => {
        c.isEditing = parent.isEditing;
        return c;
      });
      this.dataChange.next(this.data);
    }
  }
}

@Component({
  selector: 'app-jogos-configuracao',
  templateUrl: './jogos-configuracao.component.html',
  styleUrls: ['./jogos-configuracao.component.scss'],
  providers: [listDatabase]
})
export class JogosConfiguracaoComponent implements OnInit {

  configuracao: ConfiguracaoJogo = new ConfiguracaoJogo();
  buscarConfiguracaoJogoSubscription: Subscription;

  flatNodeMap = new Map<any, any>();
  nestedNodeMap = new Map<any, any>();
  selectedParent: any | null = null;
  newItemName = '';
  newFatterItem: boolean = false;

  treeControl: FlatTreeControl<any>;

  treeFlattener: MatTreeFlattener<any, any>;

  dataSource: MatTreeFlatDataSource<any, any>;

  getLevel = (node: any) => node.level;

  isExpandable = (node: any) => node.expandable;

  getChildren = (node: any): any[] => node.caracteristicas;

  hasChild = (_: number, _nodeData: any) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: any) => _nodeData.name === '';

  transformer = (node: any, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.name === node.nome
      ? existingNode
      : new Equipamento();

    flatNode.name = node.nome;
    flatNode.level = level;
    flatNode.expandable = !!node.caracteristicas;
    flatNode.index = node.index;
    flatNode.isEditing = node.isEditing;

    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  constructor(
    public jogoService: JogoService,
    public configuracaoJogoService: ConfiguracaoJogoService,
    public router: Router,
    public route: ActivatedRoute,
    public _database: listDatabase,
    public loadingService: LoadingService,
    public helper: HelperService
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<Equipamento>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe(data => {
      const req = data.map((e, i) => {
        e.index = i;
        e.caracteristicas.map((c, i) => {
          c.index = i;
          return c;
        })
        return e;
      })
      this.dataSource.data = req;
    });
  }

  ngOnInit() {
    this.route.params.subscribe(param => this.buscarConfiguracaoJogo(param.id));
  }

  ngOnDestroy() {
    this.buscarConfiguracaoJogoSubscription.unsubscribe();
  }

  buscarConfiguracaoJogo(id: number) {
    this.buscarConfiguracaoJogoSubscription = this.configuracaoJogoService.getById(id)
      .subscribe((res: ConfiguracaoJogo) => {
        this.configuracao = res;
        this._database.dataChange.next(res.equipamentos);
      })
  }

  addNewItem(node: any) {
    const parentNode = this.flatNodeMap.get(node);
    this._database.insertItem(parentNode!, '');
    this.treeControl.expand(node);
  }

  /** Save the node to database */
  saveNode(node: any, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    this._database.updateItem(nestedNode!, itemValue);
  }

  removeItem(node: Caracteristica) {
    const parentNode = this.getParentNode(node);
    this._database.removeItem(parentNode, node.index);
  }

  editItem(node: any) {
    const parentNode = this.flatNodeMap.get(node);
    this._database.editItem(parentNode!);
    this.treeControl.expand(node);
  }

  getParentNode(node: any): any | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  @ViewChild('fatherValue') fatherValueInput: ElementRef;
  adicionarEquipamento(name: string) {
    this.fatherValueInput.nativeElement.focus();
    this._database.data.push({ id: 0, nome: name, caracteristicas: [] } as any);
    this._database.dataChange.next(this._database.data);
    this.newFatterItem = false;
  }

  removerEquipamento(index) {
    this._database.data.splice(index, 1);
    this._database.dataChange.next(this._database.data);
  }

  alterarJogo() {
    this.configuracao.equipamentos = this._database.data;
    this.loadingService.present('Alterando configuração');
    this.configuracaoJogoService.patch(this.configuracao)
      .subscribe((res: any) => {
        this.router.navigate(['/jogos']).then(() => {
          this.helper.openSnackBar('Configurações atualizadas com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => this.loadingService.dismiss());
  }

}
