<h1 mat-dialog-title>Editar resposta</h1>
<div mat-dialog-content>
    <mat-form-field>
        <input matInput name="descricao" type="text" placeholder="Descrição" [(ngModel)]="resposta.descricao" required>
    </mat-form-field>

    <div class="page-form-control">
        <mat-slide-toggle (change)="onSituacaoChange($event)" [checked]="resposta.situacao == 'A' ? true : false">
            Situação:
            {{resposta.situacao == 'A' ? 'Ativo' : 'Inativo'}}</mat-slide-toggle>
    </div>

    <!-- <h3 class="page-form__title">
        Imagem (180x50)
    </h3>

    <div class="page-form-control page-form-image">
        <img [src]="resposta.imagem || 'assets/image-placeholder.png'" alt="imagem">
        <button type="button" mat-button (click)="fileInput.click()">Escolher arquivo</button>
        <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" id="imagem"
            accept="image/*">
        <button class="page-form-image__button" (click)="removeImage()" type="button">
            <i class="fas fa-trash"></i>
        </button>
    </div> -->
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancelar</button>
    <button mat-button cdkFocusInitial (click)="onYesClick()">Confirmar</button>
</div>