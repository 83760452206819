<header class="header">
    <nav class="header-nav">
        <button mat-icon-button aria-label="Abrir menu" class="header-menu-toggle" (click)="global.toggleMenu()">
            <mat-icon>menu</mat-icon>
        </button>

        <ul class="header-menu" [class.active]="global.menuOpen">
            <mat-form-field class="page-table__search">
                <input matInput [(ngModel)]="search" placeholder="Pesquise aqui">
                <button mat-button *ngIf="search" type="button" matSuffix mat-icon-button aria-label="Limpar"
                    (click)="search = null">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <li class="header-menu__item" *ngFor="let p of pages | filter:search:'name';">
                <a (click)="goTo(p.route)" mat-button class="header-menu__link">
                    <i [class]="p.icon"></i>
                    <span>{{p.name}}</span>
                </a>
            </li>

            <li class="header-menu__item">
                <a (click)="logout()" mat-button color="primary" class="header-menu__link">
                    <i class="fas fa-sign-out-alt"></i>
                    <span>Sair</span>
                </a>
            </li>
        </ul>
        <div class="header-menu-backdrop" (click)="global.toggleMenu()" [class.active]="global.menuOpen"></div>
        <a routerLink="/" class="header-logo">
            <img src="assets/logo.png" alt="Nuprocess">
        </a>

        <div class="right" *ngIf="auth.user">
            <button mat-button [matMenuTriggerFor]="userMenu" class="header-user">
                <span>Olá, <b>{{auth.user.nome}} {{auth.user.sobrenome}}</b></span>
                <div class="header-user-avatar">
                    <img [src]="auth.user.imagemPerfil || 'assets/user-avatar.png'" [alt]="auth.user.nome">
                </div>
            </button>
            <mat-menu #userMenu="matMenu">
                <button mat-menu-item [routerLink]="['/usuarios/editar', auth.user.id]">Editar perfil</button>
                <button mat-menu-item (click)="logout()">Sair</button>
            </mat-menu>
            <ul class="header-buttons">
                <li>

                </li>
            </ul>
        </div>
    </nav>
</header>