import { GlobalService } from './../../services/global.service';
import { JogosApi, JogoService } from './../../services/class/jogo.service';
import { Jogo } from './../../models/jogo.model';
import { Subscription } from 'rxjs';
import { NoticiaService } from './../../services/class/noticia.service';
import { Noticia } from './../../models/noticia.model';
// default
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';

@Component({
  selector: 'app-noticias-editar',
  templateUrl: './noticias-editar.component.html',
  styleUrls: ['./noticias-editar.component.scss']
})
export class NoticiasEditarComponent implements OnInit {

  noticia: Noticia = new Noticia();
  jogos: Jogo[] = [];

  buscarNoticiaSubscription: Subscription;
  buscarJogosSubscription: Subscription;

  file: File;
  progress: any;

  loading: boolean = false;

  ckconfig = {
    // include any other configuration you want
    // plugins: [Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize],
    image: {
      toolbar: ['imageTextAlternative', '|', 'imageStyle:full', 'imageStyle:side']
    }
  };

  constructor(
    public helper: HelperService,
    public noticiaService: NoticiaService,
    public loadingService: LoadingService,
    public jogoService: JogoService,
    public router: Router,
    public route: ActivatedRoute,
    public global: GlobalService
  ) {
    this.helper.editor
  }

  ngOnInit() {
    this.loading = true;
    this.route.params.subscribe(param => {
      this.buscarNoticiaSubscription = this.noticiaService.getById(param.id)
        .subscribe((res: Noticia) => {
          this.noticia = res;
          this.loading = false;
        }, e => this.loading = false);
    })

    this.buscarJogosSubscription = this.jogoService.get(-99, -99, '1', 'ASC')
      .subscribe((res: JogosApi) => this.jogos = res.jogos);
  }

  ngOnDestroy() {
    this.buscarJogosSubscription.unsubscribe();
    this.buscarNoticiaSubscription.unsubscribe();
  }

  abrirLink(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Salvando notícia...');
    this.noticia.slug = this.helper.convertToSlug(this.noticia.titulo);

    this.noticiaService.patch(this.noticia)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/noticia/imagem?id=${this.noticia.id}`);
        window.open(`https://blackdragons.com.br/noticias/${this.noticia.slug}`, '_blank');
        this.loadingService.dismiss();
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });
  }

  alterarNoticia(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando notícia...');
    this.noticia.slug = this.helper.convertToSlug(this.noticia.titulo);

    this.noticiaService.patch(this.noticia)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/noticia/imagem?id=${this.noticia.id}`);
        this.router.navigate(['/noticias']).then(() => {
          this.helper.openSnackBar('Notícia alterada com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.noticia.situacao = 'A' : this.noticia.situacao = 'I';
  }

  onDestaqueChange(event: MatCheckboxChange) {
    event.checked ? this.noticia.destaque = 'S' : this.noticia.destaque = 'N';
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.file, '/noticia/imagem?id=-99').then((res: any) => {
      this.noticia.imagem = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.noticiaService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImage() {
    this.noticia.imagem = '';
  }

}
