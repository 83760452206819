import { AuthService } from './../../services/auth.service';
import { NgForm } from '@angular/forms';
import { HelperService } from './../../services/helper.service';
import { Subscription } from 'rxjs';
import { LoadingService } from './../../services/loading.service';
import { UsuarioService } from './../../services/class/usuario.service';
import { Usuario } from './../../models/usuario.model';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { HttpProgressEvent } from '@angular/common/http';

@Component({
  selector: 'app-usuarios-editar',
  templateUrl: './usuarios-editar.component.html',
  styleUrls: ['./usuarios-editar.component.scss']
})
export class UsuariosEditarComponent implements OnInit {

  usuario: Usuario = new Usuario();
  loading: boolean = false;
  hide: boolean = true;

  file: File;
  progress: number = 0;

  buscarUsuarioSubscription: Subscription;
  buscarFiliaisSubscription: Subscription;

  constructor(
    public usuarioService: UsuarioService,
    public route: ActivatedRoute,
    public router: Router,
    public loadingService: LoadingService,
    public helper: HelperService,
    public auth: AuthService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => this.buscarUsuario(param.id));
  }

  ngOnDestroy() {
    this.buscarUsuarioSubscription.unsubscribe();
  }

  buscarUsuario(id: number) {
    this.loading = true;
    this.buscarUsuarioSubscription = this.usuarioService.getById(id)
      .subscribe((res: Usuario) => {
        this.usuario = res;
        this.usuario.dataNascimento = this.helper.moment(res.dataNascimento).format('DD/MM/YYYY');
        this.loading = false;
      }, e => this.loading = false)
  }

  alterarUsuario(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando usuário...');

    this.usuarioService.patch(this.usuario)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/usuario/imagemPerfil?id=${this.usuario.id}`)
        this.router.navigate(['/usuarios']).then(() => {
          if (this.auth.user.id == this.usuario.id) {
            this.auth.user = this.usuario;
            this.auth.setUser(this.usuario);
          }
          this.helper.openSnackBar('Usuário alterado com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => this.loadingService.dismiss());

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.usuario.situacao = 'A' : this.usuario.situacao = 'I';
  }

  onTestaNotificacaoChange(event: MatCheckboxChange) {
    event.checked ? this.usuario.testaNotificacao = 'S' : this.usuario.testaNotificacao = 'N';
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.file, '/usuario/imagemPerfil?id=-99').then((res: any) => {
      this.usuario.imagemPerfil = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.usuarioService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImage() {
    this.usuario.imagemPerfil = '';
  }

}
