<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Editar usuário
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" routerLink="/usuarios">
                        VOLTAR
                    </button>
                </div>
            </div>
            <form class="page-form" (ngSubmit)="alterarUsuario(form)" #form="ngForm">

                <mat-progress-bar mode="indeterminate" class="page-form-loading" *ngIf="loading"></mat-progress-bar>
                <div class="page-form-loading-shade" *ngIf="loading"></div>

                <h3 class="page-form__title">
                    Dados do usuário
                </h3>

                <div class="page-form-control" *ngIf="usuario.tipoUsuario == 'N'">
                    <label for="descricao">Testa notificação*</label>
                    <mat-checkbox (change)="onTestaNotificacaoChange($event)"
                        [checked]="usuario.testaNotificacao == 'S' ? true : false">Testa notificação</mat-checkbox>
                </div>

                <mat-form-field class="page-form-control">
                    <input matInput type="email" [pattern]="helper.emailRegex" placeholder="E-mail"
                        [(ngModel)]="usuario.email" name="email" required>
                </mat-form-field>

                <mat-form-field class="page-form-control half">
                    <input matInput type="text" placeholder="Nome" [(ngModel)]="usuario.nome" name="nome" required>
                </mat-form-field>

                <mat-form-field class="page-form-control half">
                    <input matInput type="text" placeholder="Sobrenome" [(ngModel)]="usuario.sobrenome" name="sobrenome"
                        required>
                </mat-form-field>

                <mat-form-field class="page-form-control quad">
                    <input matInput type="text" readonly disabled placeholder="Nick" [(ngModel)]="usuario.nick" name="nick">
                </mat-form-field>

                <mat-form-field class="page-form-control quad">
                    <input matInput type="text" readonly disabled placeholder="Data de nascimento"
                        [(ngModel)]="usuario.dataNascimento" name="dataNascimento" mask="00/00/0000">
                </mat-form-field>

                <mat-form-field class="page-form-control half">
                    <input matInput type="tel" readonly disabled placeholder="CPF" [(ngModel)]="usuario.cpf" name="cpf"
                        mask="000.000.000-00">
                </mat-form-field>

                <mat-form-field class="page-form-control third">
                    <input matInput type="tel" readonly disabled placeholder="CEP" [(ngModel)]="usuario.cep" name="cep"
                        mask="00000-000">
                </mat-form-field>

                <mat-form-field class="page-form-control third">
                    <input matInput type="text" readonly disabled placeholder="UF" [(ngModel)]="usuario.uf" name="uf">
                </mat-form-field>

                <mat-form-field class="page-form-control third">
                    <input matInput type="text" readonly disabled placeholder="Cidade" [(ngModel)]="usuario.cidade"
                        name="cidade">
                </mat-form-field>

                <mat-form-field class="page-form-control half">
                    <input matInput type="text" readonly disabled placeholder="Endereço" [(ngModel)]="usuario.endereco"
                        name="endereco">
                </mat-form-field>

                <mat-form-field class="page-form-control quad">
                    <input matInput type="text" readonly disabled placeholder="Número" [(ngModel)]="usuario.numeroEndereco"
                        name="numeroEndereco">
                </mat-form-field>

                <mat-form-field class="page-form-control quad">
                    <input matInput type="text" readonly disabled placeholder="Complemento" [(ngModel)]="usuario.complemento"
                        name="complemento">
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <input matInput [type]="hide ? 'password' : 'text'" placeholder="Senha" [(ngModel)]="usuario.senha"
                        name="senha" required>
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide" type="button">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>

                <hr>

                <h3 class="page-form__title">
                    Selecione uma imagem (128x128)
                </h3>

                <div class="page-form-control page-form-image">
                    <img [src]="usuario.imagemPerfil || 'assets/image-placeholder.png'" alt="Usuário">
                    <button type="button" mat-button (click)="fileInput.click()">Escolher arquivo</button>
                    <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file"
                        id="imagemPerfil" accept="image/*">
                    <button class="page-form-image__button" (click)="removeImage()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>

                <mat-slide-toggle (change)="onSituacaoChange($event)"
                    [checked]="usuario.situacao == 'A' ? true : false">Situação:
                    {{usuario.situacao == 'A' ? 'Ativo' : 'Inativo'}}</mat-slide-toggle>

                <hr>

                <button mat-raised-button color="primary" type="submit">
                    SALVAR
                </button>
            </form>
        </div>
    </div>
</section>