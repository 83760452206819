<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Configurações em jogo
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" routerLink="/jogos">
                        VOLTAR
                    </button>
                </div>
            </div>
            <div class="page-form">

                <h3 class="page-form__title">
                    Dados da configuração de jogo
                </h3>

                <div class="page-tree">

                    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                            <button mat-icon-button disabled type="button"></button>
                            <ng-container *ngIf="node.isEditing">
                                <mat-form-field>
                                    <mat-label>Característica</mat-label>
                                    <input matInput [(ngModel)]="node.name" [name]="'input ' + node.name"
                                        placeholder="Ex. Lettuce">
                                </mat-form-field>
                                <button mat-button type="button" (click)="saveNode(node, node.name)">Salvar</button>
                            </ng-container>
                            <ng-container *ngIf="!node.isEditing">
                                {{node.name}}&nbsp;&nbsp;
                            </ng-container>
                            <button mat-icon-button (click)="removeItem(node)" type="button">
                                <mat-icon>remove</mat-icon>
                            </button>
                        </mat-tree-node>

                        <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
                            <button mat-icon-button disabled type="button"></button>
                            {{node.name}}&nbsp;&nbsp;
                            <form (ngSubmit)="saveNode(node, itemValue.value)">
                                <mat-form-field>
                                    <mat-label>Nova característica</mat-label>
                                    <input matInput #itemValue placeholder="Ex. DPI">
                                </mat-form-field>
                                <button mat-button type="submit" >Salvar</button>
                            </form>
                        </mat-tree-node>

                        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                            <button mat-icon-button matTreeNodeToggle type="button"
                                [attr.aria-label]="'toggle ' + node.name">
                                <mat-icon class="mat-icon-rtl-mirror">
                                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                            </button>
                            {{node.name}}&nbsp;&nbsp;
                            <button mat-icon-button (click)="editItem(node)" type="button">
                                <mat-icon>{{!node.isEditing ? 'edit' : 'check'}}</mat-icon>
                            </button>
                            <button mat-icon-button (click)="addNewItem(node)" type="button">
                                <mat-icon>add</mat-icon>
                            </button>
                            <button mat-icon-button (click)="removerEquipamento(node.index)" type="button">
                                <mat-icon>remove</mat-icon>
                            </button>
                        </mat-tree-node>
                    </mat-tree>

                    <div class="page-tree__add" *ngIf="newFatterItem">
                        <form (ngSubmit)="adicionarEquipamento(fatherValue.value)">
                            <mat-form-field>
                                <mat-label>Novo equipamento</mat-label>
                                <input matInput #fatherValue cdkFocusInitial placeholder="Ex. Monitor">
                            </mat-form-field>
                            <button mat-button type="submit">Salvar</button>
                        </form>
                    </div>

                    <button type="button" mat-stroked-button color="primary" type="button"
                        (click)="newFatterItem = true">
                        ADICIONAR EQUIPAMENTO
                    </button>
                </div>

                <hr>

                <button type="button" mat-raised-button color="primary" (click)="alterarJogo()" type="button">
                    SALVAR
                </button>
            </div>
        </div>
    </div>
</section>