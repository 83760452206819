<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Editar linha do tempo
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" routerLink="/sobre/1">
                        VOLTAR
                    </button>
                </div>
            </div>
            <form class="page-form" (ngSubmit)="alterarLinhaDoTempo(form)" #form="ngForm">

                <mat-progress-bar mode="indeterminate" class="page-form-loading" *ngIf="loading">
                </mat-progress-bar>
                <div class="page-form-loading-shade" *ngIf="loading"></div>

                <h3 class="page-form__title">
                    Dados da linha do tempo
                </h3>

                <mat-form-field class="page-form-control">
                    <input matInput placeholder="Ano" [(ngModel)]="linhaDoTempo.ano" name="ano" required>
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <textarea matInput rows="6" placeholder="Descrição" [(ngModel)]="linhaDoTempo.descricao"
                        name="descricao" required></textarea>
                </mat-form-field>

                <hr>

                <button mat-raised-button color="primary" type="submit">
                    SALVAR
                </button>

            </form>
        </div>
    </div>
</section>