<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Editar live
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" routerLink="/lives">
                        VOLTAR
                    </button>
                </div>
            </div>
            <form class="page-form" (ngSubmit)="alterarLive(form)" #form="ngForm">

                <mat-progress-bar mode="indeterminate" class="page-form-loading" *ngIf="loading">
                </mat-progress-bar>
                <div class="page-form-loading-shade" *ngIf="loading"></div>

                <h3 class="page-form__title">
                    Dados da live
                </h3>

                <div class="page-form-control">
                    <label for="descricao">Ao vivo*</label>
                    <mat-checkbox (change)="onAoVivoChange($event)" [checked]="live.aoVivo == 'S' ? true : false">Ao
                        vivo</mat-checkbox>
                </div>

                <mat-form-field class="page-form-control">
                    <input matInput placeholder="Título" [(ngModel)]="live.titulo" name="titulo" required>
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <input matInput placeholder="Streamer" [(ngModel)]="live.streamer" name="streamer" required>
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <input matInput placeholder="Link" [pattern]="helper.urlRegex" [(ngModel)]="live.link" name="link">
                </mat-form-field>

                <hr>

                <h3 class="page-form__title">
                    Imagem (345x170)
                </h3>

                <div class="page-form-control page-form-image">
                    <img [src]="live.imagem || 'assets/image-placeholder.png'" alt="imagem">
                    <button type="button" mat-button (click)="fileInput.click()">Escolher arquivo</button>
                    <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" id="imagem"
                        accept="image/*">
                    <button class="page-form-image__button" (click)="removeImage()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>

                <hr>

                <button mat-raised-button color="primary" type="submit">
                    SALVAR
                </button>

            </form>
        </div>
    </div>
</section>