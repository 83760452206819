import { PartidaService, PartidasApi } from './../../services/class/partida.service';
import { Partida } from './../../models/partida.model';
// default
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from './../../services/global.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Jogo } from 'src/app/models/jogo.model';
import { JogoService, JogosApi } from 'src/app/services/class/jogo.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ModalCartolaComponent } from 'src/app/components/modal-cartola/modal-cartola.component';

@Component({
  selector: 'app-cartola',
  templateUrl: './cartola.component.html',
  styleUrls: ['./cartola.component.scss']
})
export class CartolaComponent implements OnInit {

  displayedColumns: string[] = ['partida', 'actions'];
  // imagem, liga, placar, resultado, data, jogo
  data: Partida[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  search: string;
  filterSubscription: Subscription;

  jogos: Jogo[] = [];
  buscarJogosSubscription: Subscription;

  idJogo: any = '%';
  jaRespondido: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public global: GlobalService,
    public partidaService: PartidaService,
    public dialog: MatDialog,
    public helper: HelperService,
    public loadingService: LoadingService,
    public jogoService: JogoService,
  ) { }

  ngOnInit() {
    this.buscarJogos();
  }

  ngOnDestroy() {
    this.buscarJogosSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.buscar();
  }

  buscar() {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.partidaService.getCartola(this.paginator.pageIndex + 1, this.paginator.pageSize, this.sort.active, this.sort.direction.toLocaleUpperCase(), this.idJogo, this.jaRespondido);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.numeroPaginas * this.paginator.pageSize;
          return data.partidas;
        }),
        catchError((err) => {
          console.log('entrei aq: ', err);
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

  filter(e) {
    if (this.paginator.pageIndex > 1) {
      this.paginator.pageIndex = 0;
    }
    if (e) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.partidaService.getCartola(this.paginator.pageIndex + 1, this.paginator.pageSize, this.sort.active, this.sort.direction.toLocaleUpperCase(), this.idJogo, this.jaRespondido, e.toLocaleLowerCase())
        .subscribe((res: PartidasApi) => {
          this.data = this.paginator.pageIndex == 0 ? res.partidas : this.data.concat(res.partidas);
        });
    } else {
      this.buscar();
    }
  }

  buscarJogos() {
    this.buscarJogosSubscription = this.partidaService.getCartolaJogos()
      .subscribe((res: Jogo[]) => this.jogos = res);
  }

  clearFilter() {
    this.search = '';
    this.buscar();
  }

  onIdJogoChange(event: MatButtonToggleChange) {
    this.buscar();
  }

  onJaRespondidoChange(event: MatSlideToggleChange) {
    this.buscar();
  }

  openDialog(partida: Partida) {
    const dialog = this.dialog.open(ModalCartolaComponent, {
      data: partida
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.buscar(); 
      }
    })
  }
}
