import { JogosApi, JogoService } from './../../services/class/jogo.service';
import { Subscription } from 'rxjs';
import { Jogo } from './../../models/jogo.model';
import { GaleriaService } from './../../services/class/galeria.service';
import { Galeria } from './../../models/galeria.model';
// default
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-comunidade-editar',
  templateUrl: './comunidade-editar.component.html',
  styleUrls: ['./comunidade-editar.component.scss']
})
export class ComunidadeEditarComponent implements OnInit {

  galeria: Galeria = new Galeria();
  jogos: Jogo[] = [];

  buscarJogosSubscription: Subscription;
  buscarGaleriaSubscription: Subscription;

  file: File;
  progress: any;

  loading: boolean = false;

  constructor(
    public helper: HelperService,
    public galeriaService: GaleriaService,
    public jogoService: JogoService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.loading = true;
      this.buscarGaleriaSubscription = this.galeriaService.getById(param.id)
        .subscribe((res: Galeria) => {
          this.galeria = res;
          this.loading = false;
        }, e => this.loading = false);
    })
    this.buscarJogos();
  }

  ngOnDestroy() {
    this.buscarJogosSubscription.unsubscribe();
  }

  buscarJogos() {
    this.buscarJogosSubscription = this.jogoService.get(-99, -99, '1', 'ASC')
      .subscribe((res: JogosApi) => {
        this.jogos = res.jogos;
      });
  }

  alterarGaleria(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando foto...');

    this.galeriaService.patch(this.galeria)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/galeria/imagem?id=${this.galeria.id}`);
        this.router.navigate(['/comunidade']).then(() => {
          this.helper.openSnackBar('Foto alterada com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.file, '/galeria/imagem?id=-99').then((res: any) => {
      this.galeria.imagem = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.galeriaService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImage() {
    this.galeria.imagem = '';
  }

}
