<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Adicionar partida
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" routerLink="/partidas">
                        VOLTAR
                    </button>
                </div>
            </div>
            <form class="page-form" (ngSubmit)="inserirPartida(form)" #form="ngForm">

                <h3 class="page-form__title">
                    Dados da partida
                </h3>

                <mat-form-field class="page-form-control third">
                    <mat-label>Status</mat-label>
                    <mat-select name="status" [(ngModel)]="partida.status" required>
                        <mat-option value="N">Nenhum</mat-option>
                        <mat-option value="A">Ao vivo</mat-option>
                        <mat-option value="V">Veja como foi</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="page-form-control two-third">
                    <mat-label>Liga</mat-label>
                    <mat-select name="liga" [(ngModel)]="partida.liga" (ngModelChange)="onLigaChange($event)" required>
                        <mat-option *ngFor="let l of ligas" [value]="l">
                            {{l.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="page-form-control" *ngIf="partida.status && partida.status !== 'N'">
                    <input matInput placeholder="Link" [(ngModel)]="partida.link" name="link">
                </mat-form-field>

                <mat-form-field class="page-form-control third">
                    <input matInput placeholder="Placar (Black dragons)" [(ngModel)]="partida.placarBlackDragons"
                        name="placarBlackDragons">
                </mat-form-field>

                <mat-form-field class="page-form-control third" *ngIf="partida.jogo.battleRoyale === 'N'">
                    <input matInput placeholder="Placar (Adversário)" [(ngModel)]="partida.placarAdversario"
                        name="placarAdversario">
                </mat-form-field>

                <mat-form-field class="page-form-control third" *ngIf="partida.jogo.battleRoyale === 'N'">
                    <mat-label>Resultado</mat-label>
                    <mat-select name="resultado" [(ngModel)]="partida.resultado">
                        <mat-option value="V">Vitória</mat-option>
                        <mat-option value="D">Derrota</mat-option>
                        <mat-option value="E">Empate</mat-option>
                        <mat-option value="W">Não comparecimento (Vitória)</mat-option>
                        <mat-option value="WD">Não comparecimento (Derrota)</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="page-form-control third">
                    <mat-label>Jogo</mat-label>
                    <mat-select name="jogo" [(ngModel)]="partida.jogo" [compareWith]="jogoService.compareFn" required
                        disabled>
                        <mat-option *ngFor="let j of jogos" [value]="j">
                            {{j.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- <mat-form-field class="page-form-control third">
                    <input matInput [matDatepicker]="dataPicker" name="data" [(ngModel)]="data"
                        placeholder="Escolha a data" required>
                    <mat-datepicker-toggle matSuffix [for]="dataPicker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #dataPicker></mat-datepicker>
                </mat-form-field> -->

                <mat-form-field class="page-form-control third">
                    <input matInput name="data" type="tel" placeholder="Data" mask="00/00/0000"
                        [dropSpecialCharacters]="false" [(ngModel)]="data" required>
                </mat-form-field>

                <mat-form-field class="page-form-control third">
                    <input matInput name="hora" type="tel" placeholder="Hora" mask="00:00"
                        [dropSpecialCharacters]="false" [(ngModel)]="hora">
                </mat-form-field>

                <mat-form-field class="page-form-control" *ngIf="partida.jogo.battleRoyale === 'N'">
                    <input matInput placeholder="Nome (Adversário)" [(ngModel)]="partida.nomeAdversario"
                        name="nomeAdversario">
                </mat-form-field>

                <mat-form-field class="page-form-control" *ngIf="partida.jogo.battleRoyale === 'S'">
                    <textarea matInput placeholder="Descrição Battle Royole" name="descricaoBattleRoyale"
                        [(ngModel)]="partida.descricaoBattleRoyale" rows="6"></textarea>
                </mat-form-field>

                <ng-container *ngIf="partida.jogo.battleRoyale === 'N'">
                    <hr>

                    <h3 class="page-form__title">
                        Imagem adversário (90x120)
                    </h3>

                    <div class="page-form-control page-form-image">
                        <img [src]="partida.imagemEscudoAdversario || 'assets/image-placeholder.png'"
                            alt="imagemEscudoAdversario">
                        <button type="button" mat-button (click)="fileInput.click()">Escolher arquivo</button>
                        <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file"
                            id="imagemEscudoAdversario" accept="image/*">
                        <button class="page-form-image__button" (click)="removeImage()" type="button">
                            <i class="fas fa-trash"></i>
                        </button>
                    </div>
                </ng-container>

                <hr>

                <button mat-raised-button color="primary" type="submit">
                    SALVAR
                </button>

            </form>
        </div>
    </div>
</section>