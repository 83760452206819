<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Editar jogo
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" routerLink="/jogos">
                        VOLTAR
                    </button>
                </div>
            </div>
            <form class="page-form" (ngSubmit)="alterarJogo(form)" #form="ngForm">

                <mat-progress-bar mode="indeterminate" class="page-form-loading" *ngIf="loading">
                </mat-progress-bar>
                <div class="page-form-loading-shade" *ngIf="loading"></div>

                <h3 class="page-form__title">
                    Dados do jogo
                </h3>

                <div class="page-form-control">
                    <label for="descricao">Battle Royale*</label>
                    <mat-checkbox (change)="onBattleRoyaleChange($event)"
                        [checked]="jogo.battleRoyale === 'S' ? true : false">Battle Royale</mat-checkbox>
                </div>

                <mat-form-field class="page-form-control two-third">
                    <input matInput type="text" placeholder="Nome" [(ngModel)]="jogo.nome" name="nome" required>
                </mat-form-field>

                <mat-form-field class="page-form-control third">
                    <input matInput type="tel" placeholder="Ordem" [(ngModel)]="jogo.ordem" name="ordem" mask="0*"
                        required>
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <textarea matInput rows="6" placeholder="Descrição" [(ngModel)]="jogo.descricao"
                        name="descricao"></textarea>
                </mat-form-field>

                <hr>

                <h3 class="page-form__title">
                    Imagem (1920x500)
                </h3>

                <div class="page-form-control page-form-image">
                    <img [src]="jogo.imagem || 'assets/image-placeholder.png'" alt="Jogo">
                    <button type="button" mat-button (click)="fileInput.click()">Escolher arquivo</button>
                    <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" id="Jogo imagem"
                        accept="image/*">
                    <button class="page-form-image__button" (click)="removeImage()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>

                <h3 class="page-form__title">
                    Ícone (250x120)
                </h3>

                <div class="page-form-control page-form-image">
                    <img [src]="jogo.icone || 'assets/image-placeholder.png'" alt="Jogo">
                    <button type="button" mat-button (click)="fileIconeInput.click()">Escolher arquivo</button>
                    <input hidden (change)="onFileIconeSelected($event.target.files)" #fileIconeInput type="file"
                        id="Jogo ícone" accept="image/*">
                    <button class="page-form-image__button" (click)="removeIcone()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>

                <div class="page-form-control">
                    <mat-slide-toggle (change)="onSituacaoChange($event)"
                        [checked]="jogo.situacao == 'A' ? true : false">Situação:
                        {{jogo.situacao == 'A' ? 'Ativo' : 'Inativo'}}</mat-slide-toggle>
                </div>

                <hr>

                <button mat-raised-button color="primary" type="submit">
                    SALVAR
                </button>

            </form>
        </div>
    </div>
</section>