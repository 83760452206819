<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Palpitei
                </h1>
            </div>


            <div class="page-table">
                <div class="loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
                    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                    <div class="rate-limit-reached" *ngIf="isRateLimitReached">
                        Não foi possível acessar os dados dessa listagem, aguarde um pouco e tente novamente.
                    </div>
                </div>

                <mat-form-field class="page-table__search">
                    <input matInput [(ngModel)]="search" (ngModelChange)="filter($event)" placeholder="Pesquise aqui">
                    <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Limpar"
                        (click)="clearFilter()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <div class="filters">
                    <mat-button-toggle-group name="idJogo" aria-label="idJogo" [(ngModel)]="idJogo"
                        (ngModelChange)="onIdJogoChange($event)">
                        <mat-button-toggle [value]="'%'">TODOS</mat-button-toggle>
                        <mat-button-toggle *ngFor="let j of jogos; let i = index;" [value]="j.id">
                            {{j.nome}}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                    <mat-slide-toggle [(ngModel)]="jaRespondido" name="jaRespondido" [labelPosition]="'before'"
                        (ngModelChange)="onJaRespondidoChange($event)">Já
                        respondido</mat-slide-toggle>
                </div>

                <div class="page-table-container">
                    <div class="loading-shade" *ngIf="!isLoadingResults && !data.length">
                        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                        <div class="rate-limit-reached">
                            Nenhum registro encontrado
                        </div>
                    </div>

                    <table mat-table [dataSource]="data" matSort matSortActive="0" matSortDirection="desc">

                        <ng-container matColumnDef="partida">
                            <th mat-header-cell *matHeaderCellDef class="text-center">
                                Partida
                            </th>
                            <td mat-cell *matCellDef="let row" class="text-center">
                                <div class="partida">
                                    <div class="team">
                                        <img src="assets/logo.png" alt="Black dragons">
                                        <span>Black Dragons</span>
                                    </div>
                                    <div class="content">
                                        <h3>{{row.liga.nome}}</h3>
                                        <strong>{{row.jogo.nome}}</strong>
                                        <h2>VS</h2>
                                        <span>
                                            {{helper.moment(row.data).format('DD.MM.YYYY')}}
                                            {{helper.moment(row.hora).format('HH:mm')}}
                                        </span>
                                    </div>
                                    <div class="team">
                                        <img [src]="row.imagemEscudoAdversario || 'assets/image-placeholder.png'"
                                            [alt]="row.liga" class="page-table-image">
                                        <span>{{row.nomeAdversario}}</span>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row">
                                <button mat-raised-button mat-button color="primary" (click)="openDialog(row)">
                                    Responder
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [length]="resultsLength" [pageSizeOptions]="[15, 25, 40, 50]" [pageSize]="15">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</section>