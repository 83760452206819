<section class="page">
  <div class="container">
    <div class="page-wrapper">
      <div class="page-header">
        <h1 class="page-title">
          Contato
        </h1>
      </div>
      <form class="page-form" (ngSubmit)="alterarContatoGeral(form)" #form="ngForm">

        <h3 class="page-form__title">
          Contato do site
        </h3>

        <mat-form-field class="page-form-control">
          <input matInput type="text" placeholder="Instagram (opcional)" [(ngModel)]="contatoGeral.linkInstagram"
            name="linkInstagram">
        </mat-form-field>

        <mat-form-field class="page-form-control">
          <input matInput type="text" placeholder="Twitter (opcional)" [(ngModel)]="contatoGeral.linkTwitter"
            name="linkTwitter">
        </mat-form-field>

        <mat-form-field class="page-form-control">
          <input matInput type="text" placeholder="Facebook (opcional)" [(ngModel)]="contatoGeral.linkFacebook"
            name="linkFacebook">
        </mat-form-field>

        <mat-form-field class="page-form-control">
          <input matInput type="text" placeholder="Youtube (opcional)" [(ngModel)]="contatoGeral.linkYoutube"
            name="linkYoutube">
        </mat-form-field>

        <mat-form-field class="page-form-control">
          <input matInput type="text" placeholder="Linkedin (opcional)" [(ngModel)]="contatoGeral.linkLinkedin"
            name="linkLinkedin">
        </mat-form-field>

        <mat-form-field class="page-form-control">
          <input matInput type="text" placeholder="Tiktok (opcional)" [(ngModel)]="contatoGeral.linkTiktok"
            name="linkTiktok">
        </mat-form-field>

        <mat-form-field class="page-form-control">
          <input matInput type="text" placeholder="App Store (opcional)" [(ngModel)]="contatoGeral.linkAppStore"
            name="linkAppStore">
        </mat-form-field>

        <mat-form-field class="page-form-control">
          <input matInput type="text" placeholder="Play Store (opcional)" [(ngModel)]="contatoGeral.linkPlayStore"
            name="linkPlayStore">
        </mat-form-field>

        <hr>

        <mat-form-field class="page-form-control">
          <input matInput type="email" [pattern]="helper.emailRegex" placeholder="E-mail"
            [(ngModel)]="contatoGeral.email" name="email" required>
          <mat-hint>
            E-mail que será utilizado pelo site para envio de formulários. Não pode ser de domínio
            diferente do site.
          </mat-hint>
        </mat-form-field>

        <button mat-raised-button color="primary" type="submit">
          SALVAR
        </button>

      </form>
    </div>
  </div>
</section>
