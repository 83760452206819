<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Configurações do jogador
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" routerLink="/jogadores">
                        VOLTAR
                    </button>
                </div>
            </div>
            <div class="page-form">

                <h3 class="page-form__title">
                    Dados da configuração de jogo
                </h3>

                <div class="page-tree">

                    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                        <!-- This is the tree node template for leaf nodes -->
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                            <!-- use a disabled button to provide padding for tree leaf -->
                            <button mat-icon-button disabled></button>
                            <mat-form-field>
                                <input matInput [placeholder]="node.name" [name]="'name ' + node.valor"
                                    [(ngModel)]="node.valor" (ngModelChange)="changeValor(node, $event)">
                            </mat-form-field>
                        </mat-tree-node>
                        <!-- This is the tree node template for expandable nodes -->
                        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                                <mat-icon class="mat-icon-rtl-mirror">
                                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                            </button>
                            {{node.name}}
                        </mat-tree-node>
                    </mat-tree>
                </div>

                <hr>

                <button type="button" mat-raised-button color="primary" (click)="alterarConfiguracaoJogo()"
                    type="button">
                    SALVAR
                </button>
            </div>
        </div>
    </div>
</section>