<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Adicionar notificação
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" routerLink="/notificacoes">
                        VOLTAR
                    </button>
                </div>
            </div>
            <form class="page-form" (ngSubmit)="inserirNotificacao(form)" #form="ngForm">

                <h3 class="page-form__title">
                    Dados da notificação
                </h3>

                <div class="page-form-control half">
                    <label id="page-radio-label">Tipo da notificação</label>
                    <mat-radio-group class="page-radio-group" [(ngModel)]="notificacao.tipoNotificacao"
                        name="tipoNotificacao">
                        <mat-radio-button class="page-radio-button" value="M">
                            Manual
                        </mat-radio-button>
                        <mat-radio-button class="page-radio-button" value="A">
                            Automática
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <div class="page-form-control half" style="text-align: right;">
                    <mat-slide-toggle (change)="onSituacaoChange($event)"
                        [checked]="notificacao.situacao == 'A' ? true : false">Situação:
                        {{notificacao.situacao == 'A' ? 'Ativo' : 'Inativo'}}</mat-slide-toggle>
                </div>

                <mat-form-field class="page-form-control">
                    <input matInput placeholder="Título" [(ngModel)]="notificacao.titulo" name="titulo" required>
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <input matInput placeholder="Sub-título" [(ngModel)]="notificacao.subTitulo" name="subTitulo"
                        required>
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <input matInput placeholder="Link" [(ngModel)]="notificacao.link" name="link">
                </mat-form-field>

                <h3 class="page-form__title">
                    Imagem (300x200) OBS: Essa imagem vai aparecer junto com a notificação.
                </h3>

                <div class="page-form-control page-form-image">
                    <img [src]="notificacao.imagem || 'assets/image-placeholder.png'" alt="imagem">
                    <button type="button" mat-button (click)="fileInput.click()">Escolher arquivo</button>
                    <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" id="imagem"
                        accept="image/*">
                    <button class="page-form-image__button" (click)="removeImage()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>

                <ng-container *ngIf="notificacao.tipoNotificacao === 'A'">
                    <hr>

                    <h3 class="page-form__title">
                        Dados da configuração
                    </h3>

                    <mat-form-field class="page-form-control half">
                        <input matInput [matDatepicker]="dataInicialPicker" name="dataInicial"
                            [(ngModel)]="notificacao.dataInicial" placeholder="Escolha a data inicial da notificação"
                            required disabled>
                        <mat-datepicker-toggle matSuffix [for]="dataInicialPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dataInicialPicker disabled="false"></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="page-form-control half">
                        <input matInput [matDatepicker]="dataFinalPicker" name="dataFinal"
                            [(ngModel)]="notificacao.dataFinal" placeholder="Escolha a data final da notificação"
                            required disabled>
                        <mat-datepicker-toggle matSuffix [for]="dataFinalPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dataFinalPicker disabled="false"></mat-datepicker>
                    </mat-form-field>

                    <div class="page-form-control">
                        <label for="">Selecione os dias</label>
                        <div class="checkbox-list">
                            <div class="checkbox-item">
                                <mat-checkbox (change)="onDomingoChange($event)"
                                    [checked]="notificacao.domingo === 'S' ? true : false">
                                    Domingo</mat-checkbox>
                            </div>
                            <div class="checkbox-item">
                                <mat-checkbox (change)="onSegundaChange($event)"
                                    [checked]="notificacao.segunda === 'S' ? true : false">
                                    Segunda</mat-checkbox>
                            </div>
                            <div class="checkbox-item">
                                <mat-checkbox (change)="onTercaChange($event)"
                                    [checked]="notificacao.terca === 'S' ? true : false">
                                    Terça</mat-checkbox>
                            </div>
                            <div class="checkbox-item">
                                <mat-checkbox (change)="onQuartaChange($event)"
                                    [checked]="notificacao.quarta === 'S' ? true : false">
                                    Quarta</mat-checkbox>
                            </div>
                            <div class="checkbox-item">
                                <mat-checkbox (change)="onQuintaChange($event)"
                                    [checked]="notificacao.quinta === 'S' ? true : false">
                                    Quinta</mat-checkbox>
                            </div>
                            <div class="checkbox-item">
                                <mat-checkbox (change)="onSextaChange($event)"
                                    [checked]="notificacao.sexta === 'S' ? true : false">
                                    Sexta</mat-checkbox>
                            </div>
                            <div class="checkbox-item">
                                <mat-checkbox (change)="onSabadoChange($event)"
                                    [checked]="notificacao.sabado === 'S' ? true : false">
                                    Sábado</mat-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="page-form-control">
                        <label id="page-radio-label">Tipo de horário</label>
                        <mat-radio-group class="page-radio-group" [(ngModel)]="notificacao.tipoHorario"
                            name="tipoHorario">
                            <mat-radio-button class="page-radio-button" value="F">
                                Fixo
                            </mat-radio-button>
                            <mat-radio-button class="page-radio-button" value="A">
                                Automático
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <mat-form-field class="page-form-control half" *ngIf="notificacao.tipoHorario === 'F'">
                        <input type="tel" matInput placeholder="Hora fixa" [(ngModel)]="notificacao.horaFixa"
                            name="horaFixa" mask="00:00" required>
                    </mat-form-field>

                    <mat-form-field class="page-form-control half" *ngIf="notificacao.tipoHorario === 'A'">
                        <input type="tel" matInput placeholder="Hora inicial"
                            [(ngModel)]="notificacao.horaInicialAutomatica" name="horaInicialAutomatica" mask="00:00"
                            required>
                    </mat-form-field>

                    <mat-form-field class="page-form-control half" *ngIf="notificacao.tipoHorario === 'A'">
                        <input type="tel" matInput placeholder="Hora final"
                            [(ngModel)]="notificacao.horaFinalAutomatica" name="horaFinalAutomatica" mask="00:00"
                            required>
                    </mat-form-field>
                </ng-container>

                <hr>

                <button mat-raised-button color="primary" type="submit">
                    SALVAR
                </button>

            </form>
        </div>
    </div>
</section>