<section class="page">
  <div class="container">
    <div class="page-wrapper">
      <div class="page-header">
        <h1 class="page-title">
          Adicionar membro da equipe
        </h1>
        <div class="page-header__buttons">
          <button mat-stroked-button color="primary" routerLink="/equipe">
            VOLTAR
          </button>
        </div>
      </div>
      <form class="page-form" (ngSubmit)="inserirEquipe(form)" #form="ngForm">

        <h3 class="page-form__title">
          Dados do membro
        </h3>

        <mat-form-field class="page-form-control two-third">
          <input matInput type="text" placeholder="Nome" [(ngModel)]="equipe.nome" name="nome" required>
        </mat-form-field>

        <mat-form-field class="page-form-control third">
          <input matInput type="tel" placeholder="Ordem" [(ngModel)]="equipe.ordem" name="ordem" required>
        </mat-form-field>

        <mat-form-field class="page-form-control half">
          <input matInput type="text" placeholder="Cargo" [(ngModel)]="equipe.cargo" name="cargo" required>
        </mat-form-field>

        <mat-form-field class="page-form-control half">
          <input matInput type="email" [pattern]="helper.emailRegex" placeholder="E-mail" [(ngModel)]="equipe.email"
            name="email">
        </mat-form-field>

        <hr>

        <mat-form-field class="page-form-control">
          <input matInput type="text" placeholder="Facebook (opcional)" [(ngModel)]="equipe.linkFacebook"
            name="linkFacebook">
        </mat-form-field>

        <mat-form-field class="page-form-control">
          <input matInput type="text" placeholder="Twitter (opcional)" [(ngModel)]="equipe.linkTwitter"
            name="linkTwitter">
        </mat-form-field>

        <mat-form-field class="page-form-control">
          <input matInput type="text" placeholder="Instagram (opcional)" [(ngModel)]="equipe.linkInstagram"
            name="linkInstagram">
        </mat-form-field>

        <mat-form-field class="page-form-control">
          <input matInput type="text" placeholder="Linkedin (opcional)" [(ngModel)]="equipe.linkLinkedin"
            name="linkLinkedin">
        </mat-form-field>

        <mat-form-field class="page-form-control">
          <input matInput type="text" placeholder="Tiktok (opcional)" [(ngModel)]="equipe.linkTiktok" name="linkTiktok">
        </mat-form-field>

        <hr>

        <h3 class="page-form__title">
          Imagem (130x192)
        </h3>

        <div class="page-form-control page-form-image">
          <img [src]="equipe.imagem || 'assets/image-placeholder.png'" alt="Equipe">
          <button type="button" mat-button (click)="fileInput.click()">Escolher arquivo</button>
          <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" id="Equipe imagem"
            accept="image/*">
          <button class="page-form-image__button" (click)="removeImage()" type="button">
            <i class="fas fa-trash"></i>
          </button>
        </div>

        <div class="page-form-control">
          <mat-slide-toggle (change)="onSituacaoChange($event)"
            [checked]="equipe.situacao == 'A' ? true : false">Situação:
            {{equipe.situacao == 'A' ? 'Ativo' : 'Inativo'}}</mat-slide-toggle>
        </div>

        <hr>

        <button mat-raised-button color="primary" type="submit">
          SALVAR
        </button>

      </form>
    </div>
  </div>
</section>
