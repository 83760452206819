import { Router } from '@angular/router';
import { JogoService } from './../../services/class/jogo.service';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Jogo } from './../../models/jogo.model';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-jogos-criar',
  templateUrl: './jogos-criar.component.html',
  styleUrls: ['./jogos-criar.component.scss']
})
export class JogosCriarComponent implements OnInit {

  jogo: Jogo = new Jogo();

  file: File;
  fileIcone: File;
  progress: any;

  constructor(
    public helper: HelperService,
    public jogoService: JogoService,
    public loadingService: LoadingService,
    public router: Router
  ) { }

  ngOnInit() {
  }

  inserirJogo(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Inserindo jogo...');
    this.jogo.slug = this.helper.convertToSlug(this.jogo.nome);

    this.jogoService.post(this.jogo)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/jogo/imagem?id=${res}`);
        this.submitImages(this.fileIcone, `/jogo/icone?id=${res}`);
        this.router.navigate(['/jogos']).then(() => {
          this.helper.openSnackBar('Jogo inserido com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.jogo.situacao = 'A' : this.jogo.situacao = 'I';
  }

  onBattleRoyaleChange(event: MatCheckboxChange) {
    event.checked ? this.jogo.battleRoyale = 'S' : this.jogo.battleRoyale = 'N';
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('0%')
    this.submitImages(this.file, '/jogo/imagem?id=-99').then((res: any) => {
      this.jogo.imagem = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  @ViewChild('fileIconeInput', { static: true }) fileIconeInput: ElementRef;
  onFileIconeSelected(files) {
    this.fileIcone = files.item(0);
    this.loadingService.present('0%')
    this.submitImages(this.fileIcone, '/jogo/icone?id=-99').then((res: any) => {
      this.jogo.icone = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileIconeInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.jogoService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImage() {
    this.jogo.imagem = '';
  }

  removeIcone() {
    this.jogo.icone = '';
  }

}
