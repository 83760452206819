import { LoadingService } from './../../services/loading.service';
import { NgForm } from '@angular/forms';
import { HttpProgressEvent } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from './../../services/helper.service';
import { SobreService } from './../../services/class/sobre.service';
import { Subscription } from 'rxjs';
import { Sobre } from './../../models/sobre.model';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatTab, MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss']
})
export class SobreComponent implements OnInit, AfterViewInit {

  @ViewChild('tab') tabElement: MatTabGroup;

  sobre: Sobre = new Sobre();

  buscarSobreSubscription: Subscription;

  fileImagemPequena: File;
  fileImagemGrande: File;

  progress: number = 0;

  loading: boolean = false;

  constructor(
    public sobreService: SobreService,
    public helper: HelperService,
    public route: ActivatedRoute,
    public loadingService: LoadingService,
    public router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.buscarSobreSubscription = this.sobreService.get()
      .subscribe((res: Sobre) => {
        this.sobre = res;
        this.loading = false;
      }, e => this.loading = false);
  }

  ngAfterViewInit() {
    this.route.params.subscribe(param => this.tabElement.selectedIndex = param.tab);
  }

  ngOnDestroy() {
    this.buscarSobreSubscription.unsubscribe();
  }

  alterarSobre(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando sobre...');

    this.sobreService.patch(this.sobre)
      .subscribe((res: any) => {
        this.submitImages(this.fileImagemPequena, `/sobre/imagemPequena?id=${this.sobre.id}`);
        this.submitImages(this.fileImagemGrande, `/sobre/imagemGrande?id=${this.sobre.id}`);
        this.helper.openSnackBar('Sobre alterado com sucesso.');
        this.loadingService.dismiss();
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  @ViewChild('fileImagemPequenaInput', { static: true }) fileImagemPequenaInput: ElementRef;
  onFileImagemPequenaSelected(files) {
    this.fileImagemPequena = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.fileImagemPequena, '/sobre/imagemPequena?id=-99').then((res: any) => {
      this.sobre.imagemPequena = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileImagemPequenaInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  @ViewChild('fileImagemGrandeInput', { static: true }) fileImagemGrandeInput: ElementRef;
  onFileImagemGrandeSelected(files) {
    this.fileImagemGrande = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.fileImagemGrande, '/sobre/imagemGrande?id=-99').then((res: any) => {
      this.sobre.imagemGrande = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileImagemGrandeInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.sobreService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  selectedTabChange(e) {
    this.router.navigate(['/sobre', e.index]);
  }

  removeImagePequena() {
    this.sobre.imagemPequena = '';
  }

  removeImageGrande() {
    this.sobre.imagemGrande = '';
  }
}