<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Adicionar jogador
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" routerLink="/jogadores">
                        VOLTAR
                    </button>
                </div>
            </div>
            <form class="page-form" (ngSubmit)="inserirJogador(form)" #form="ngForm">

                <h3 class="page-form__title">
                    Dados do jogador
                </h3>

                <mat-form-field class="page-form-control">
                    <input matInput placeholder="Nome" [(ngModel)]="jogador.nome" name="nome" required>
                </mat-form-field>

                <mat-form-field class="page-form-control half">
                    <input matInput placeholder="Apelido" [(ngModel)]="jogador.apelido" name="apelido" required>
                </mat-form-field>

                <mat-form-field class="page-form-control half">
                    <input matInput placeholder="Posição" [(ngModel)]="jogador.posicao" name="posicao">
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <textarea matInput rows="6" placeholder="Descrição" [(ngModel)]="jogador.descricao"
                        name="descricao"></textarea>
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <mat-label>Time</mat-label>
                    <mat-select name="time" [(ngModel)]="jogador.time" required>
                        <mat-option *ngFor="let t of times" [value]="t">
                            {{t.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <input matInput placeholder="Instagram (opcional)" [(ngModel)]="jogador.linkInstagram"
                        name="linkInstagram">
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <input matInput placeholder="Facebook (opcional)" [(ngModel)]="jogador.linkFacebook"
                        name="linkFacebook">
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <input matInput placeholder="Twitter (opcional)" [(ngModel)]="jogador.linkTwitter"
                        name="linkTwitter">
                </mat-form-field>

                <hr>

                <h3 class="page-form__title">
                    Foto (110x205)
                </h3>

                <div class="page-form-control page-form-image">
                    <img [src]="jogador.foto || 'assets/image-placeholder.png'" alt="Foto">
                    <button type="button" mat-button (click)="fileInput.click()">Escolher arquivo</button>
                    <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" id="foto"
                        accept="image/*">
                    <button class="page-form-image__button" (click)="removeFoto()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>

                <div class="page-form-control">
                    <mat-slide-toggle (change)="onSituacaoChange($event)"
                        [checked]="jogador.situacao == 'A' ? true : false">Situação:
                        {{jogador.situacao == 'A' ? 'Ativo' : 'Inativo'}}</mat-slide-toggle>
                </div>

                <hr>

                <button mat-raised-button color="primary" type="submit">
                    SALVAR
                </button>

            </form>
        </div>
    </div>
</section>