<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Partidas
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="warn" [disabled]="!selection.selected.length"
                        (click)="deletarPartidas()">
                        DELETAR ITENS
                    </button>
                    <button mat-raised-button color="primary" routerLink="/partidas/adicionar">
                        ADICIONAR
                    </button>
                </div>
            </div>

            <!-- idLiga, idJogo, Data Inicial, Data Final, filtroTelaData -->
            <div class="page-filter">

                <mat-form-field class="page-filter-item" appearance="fill">
                    <mat-label>Selecione seu filtro</mat-label>
                    <mat-select id="filtroTelaData" name="filtroTelaData" [(ngModel)]="filtroTelaData"
                        (ngModelChange)="onFiltroTelaDataChange($event)">
                        <mat-option [value]="-99">Todos</mat-option>
                        <mat-option [value]="1">Diário</mat-option>
                        <mat-option [value]="2">Semanal</mat-option>
                        <mat-option [value]="3">Mensal</mat-option>
                        <mat-option [value]="4">Período</mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-container *ngIf="filtroTelaData === 4">
                    <mat-form-field class="page-filter-item" appearance="fill">
                        <mat-label>Data inicial</mat-label>
                        <input matInput [max]="dataFinal" [(ngModel)]="dataInicial" name="dataInicial"
                            [matDatepicker]="dataInicialPicker" (ngModelChange)="onDataInicialChange($event)">
                        <mat-datepicker-toggle matSuffix [for]="dataInicialPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dataInicialPicker></mat-datepicker>
                    </mat-form-field>
    
                    <mat-form-field class="page-filter-item" appearance="fill">
                        <mat-label>Data final</mat-label>
                        <input matInput [min]="dataInicial" [(ngModel)]="dataFinal" name="dataFinal"
                            [matDatepicker]="dataFinalPicker" (ngModelChange)="onDataFinalChange($event)">
                        <mat-datepicker-toggle matSuffix [for]="dataFinalPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dataFinalPicker></mat-datepicker>
                    </mat-form-field>
                </ng-container>

                <mat-form-field class="page-filter-item" appearance="fill">
                    <mat-label>Liga</mat-label>
                    <mat-select name="idLiga" [(ngModel)]="idLiga" (ngModelChange)="onLigaChange($event)">
                        <mat-option>Selecionar</mat-option>
                        <mat-option *ngFor="let l of ligas" [value]="l.id">
                            {{l.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="page-filter-item" appearance="fill">
                    <mat-label>Jogo</mat-label>
                    <mat-select name="idJogo" [(ngModel)]="idJogo" (ngModelChange)="onJogoChange($event)">
                        <mat-option>Selecionar</mat-option>
                        <mat-option *ngFor="let j of jogos" [value]="j.id">
                            {{j.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="page-table">
                <div class="loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
                    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                    <div class="rate-limit-reached" *ngIf="isRateLimitReached">
                        Não foi possível acessar os dados dessa listagem, aguarde um pouco e tente novamente.
                    </div>
                </div>

                <mat-form-field class="page-table__search">
                    <input matInput [(ngModel)]="search" (ngModelChange)="filter($event)" placeholder="Pesquise aqui">
                    <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Limpar"
                        (click)="clearFilter()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <div class="page-table-container">
                    <div class="loading-shade" *ngIf="!isLoadingResults && !data.length">
                        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                        <div class="rate-limit-reached">
                            Nenhum registro encontrado
                        </div>
                    </div>

                    <table mat-table [dataSource]="data" matSort matSortActive="0" matSortDirection="asc">

                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="imagem">
                            <th mat-header-cell *matHeaderCellDef>
                                Imagem
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <img [src]="row.imagemEscudoAdversario || 'assets/image-placeholder.png'"
                                    [alt]="row.liga" class="page-table-image" *ngIf="row.jogo.battleRoyale === 'N'">
                                <img [src]="row.jogo.icone || 'assets/image-placeholder.png'" [alt]="row.liga"
                                    class="page-table-image" *ngIf="row.jogo.battleRoyale === 'S'">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="adversario">
                            <th mat-header-cell *matHeaderCellDef>
                                Adversário
                            </th>
                            <td mat-cell *matCellDef="let row">
                                {{row.nomeAdversario}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="1">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Liga
                            </th>
                            <td mat-cell *matCellDef="let row">{{row.liga?.nome}}</td>
                        </ng-container>

                        <ng-container matColumnDef="2">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Placar
                            </th>
                            <td mat-cell *matCellDef="let row">{{row.placarBlackDragons}} - {{row.placarAdversario}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="3">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Resultado
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <ng-container *ngIf="row.resultado === 'V'">Vitória</ng-container>
                                <ng-container *ngIf="row.resultado === 'D'">Derrota</ng-container>
                                <ng-container *ngIf="row.resultado === 'E'">Empate</ng-container>
                                <ng-container *ngIf="row.resultado === 'W'">W.O</ng-container>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="4">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Data
                            </th>
                            <td mat-cell *matCellDef="let row">
                                {{helper.moment(row.data).format('DD/MM/YYYY')}}
                                <ng-container *ngIf="row.hora">{{helper.moment(row.hora).format('HH:mm')}}
                                </ng-container>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="5">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Jogo
                            </th>
                            <td mat-cell *matCellDef="let row">{{row.jogo.nome}}</td>
                        </ng-container>

                        <ng-container matColumnDef="6">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Status
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <ng-container *ngIf="row.status === 'N'">
                                    Nenhum
                                </ng-container>
                                <ng-container *ngIf="row.status === 'A'">
                                    Ao vivo
                                </ng-container>
                                <ng-container *ngIf="row.status === 'V'">
                                    Veja como foi
                                </ng-container>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row">
                                <button mat-icon-button mat-button matTooltip="Editar" aria-label="Editar"
                                    color="primary" [routerLink]="['/partidas/editar', row.id]">
                                    <mat-icon>create</mat-icon>
                                </button>
                                <button mat-icon-button mat-button matTooltip="Excluir" aria-label="Excluir"
                                    color="warn" (click)="deletarPartida(row)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [length]="resultsLength" [pageSizeOptions]="[15, 25, 40, 50]" [pageSize]="15">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</section>