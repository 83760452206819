<section class="modal-galeria" [class.active]="modalGaleriaService.active">
    <form class="page-form" (ngSubmit)="inserirGaleria(form)" #form="ngForm">

        <h3 class="page-form__title">
            Dados do foto/wallpaper
        </h3>

        <mat-form-field class="page-form-control third">
            <mat-label>Jogo</mat-label>
            <mat-select name="jogo" [(ngModel)]="galeria.jogo" required>
                <mat-option *ngFor="let j of jogos" [value]="j">
                    {{j.nome}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="page-form-control third">
            <mat-label>Tipo</mat-label>
            <mat-select name="tipo" [(ngModel)]="galeria.tipo" required>
                <mat-option value="F">Foto</mat-option>
                <mat-option value="W">Wallpaper</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="page-form-control third">
            <mat-label>Plataforma</mat-label>
            <mat-select name="plataforma" [(ngModel)]="galeria.plataforma" required>
                <mat-option value="D">Desktop</mat-option>
                <mat-option value="M">Mobile</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelect($event)">
            <ngx-dropzone-label>
                <div>
                    <h2>Arraste as imagens para a galeria</h2>
                </div>
            </ngx-dropzone-label>
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
                [removable]="true" (removed)="onRemove(f)">
                <ngx-dropzone-label></ngx-dropzone-label>
            </ngx-dropzone-image-preview>
        </div>

        <hr>

        <button mat-raised-button color="primary" type="submit">
            SALVAR
        </button>

    </form>
</section>
<div class="modal-galeria-backdrop" [class.active]="modalGaleriaService.active" (click)="modalGaleriaService.dismiss()">
</div>