<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Editar Foto/Wallpaper
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" routerLink="/comunidade">
                        VOLTAR
                    </button>
                </div>
            </div>
            <form class="page-form" (ngSubmit)="alterarGaleria(form)" #form="ngForm">

                <mat-progress-bar mode="indeterminate" class="page-form-loading" *ngIf="loading">
                </mat-progress-bar>
                <div class="page-form-loading-shade" *ngIf="loading"></div>

                <h3 class="page-form__title">
                    Dados do foto/wallpaper
                </h3>

                <mat-form-field class="page-form-control third">
                    <mat-label>Jogo</mat-label>
                    <mat-select name="jogo" [(ngModel)]="galeria.jogo" [compareWith]="jogoService.compareFn" required>
                        <mat-option *ngFor="let j of jogos" [value]="j">
                            {{j.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="page-form-control third">
                    <mat-label>Tipo</mat-label>
                    <mat-select name="tipo" [(ngModel)]="galeria.tipo" required>
                        <mat-option value="F">Foto</mat-option>
                        <mat-option value="W">Wallpaper</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="page-form-control third">
                    <mat-label>Plataforma</mat-label>
                    <mat-select name="plataforma" [(ngModel)]="galeria.plataforma" required>
                        <mat-option value="D">Desktop</mat-option>
                        <mat-option value="M">Mobile</mat-option>
                    </mat-select>
                </mat-form-field>

                <hr>

                <h3 class="page-form__title">
                    Imagem
                </h3>

                <div class="page-form-control page-form-image">
                    <img [src]="galeria.imagem || 'assets/image-placeholder.png'" alt="imagem">
                    <button type="button" mat-button (click)="fileInput.click()">Escolher arquivo</button>
                    <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" id="imagem"
                        accept="image/*">
                    <button class="page-form-image__button" (click)="removeImage()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>

                <hr>

                <button mat-raised-button color="primary" type="submit">
                    SALVAR
                </button>

            </form>
        </div>
    </div>
</section>