<h1 mat-dialog-title>{{pergunta.descricao}}</h1>
<div mat-dialog-content>
    <mat-radio-group aria-labelledby="respostas" class="radio-group" [(ngModel)]="idResposta">
        <mat-radio-button class="radio-button" *ngFor="let r of pergunta.respostas" [value]="r.id">
            {{r.descricao}}
        </mat-radio-button>
    </mat-radio-group>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancelar</button>
    <button mat-button cdkFocusInitial (click)="onYesClick()">Confirmar</button>
</div>