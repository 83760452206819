<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Editar patrocinador
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" routerLink="/patrocinadores">
                        VOLTAR
                    </button>
                </div>
            </div>
            <form class="page-form" (ngSubmit)="alterarPatrocinador(form)" #form="ngForm">

                <mat-progress-bar mode="indeterminate" class="page-form-loading" *ngIf="loading">
                </mat-progress-bar>
                <div class="page-form-loading-shade" *ngIf="loading"></div>

                <h3 class="page-form__title">
                    Dados do patrocinador
                </h3>

                <mat-form-field class="page-form-control">
                    <input matInput placeholder="Nome" [(ngModel)]="patrocinador.nome" name="nome" required>
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <input matInput placeholder="Link" [pattern]="helper.urlRegex" [(ngModel)]="patrocinador.linkSite"
                        name="link">
                </mat-form-field>

                <mat-form-field class="page-form-control">
                    <textarea matInput rows="6" placeholder="Descrição" [(ngModel)]="patrocinador.descricao"
                        name="descricao" required></textarea>
                </mat-form-field>

                <hr>

                <h3 class="page-form__title">
                    Imagem (180xAUTO)
                </h3>

                <div class="page-form-control page-form-image">
                    <img [src]="patrocinador.imagem || 'assets/image-placeholder.png'" alt="imagem">
                    <button type="button" mat-button (click)="fileInput.click()">Escolher arquivo</button>
                    <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" id="imagem"
                        accept="image/*">
                    <button class="page-form-image__button" (click)="removeImage()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>

                <h3 class="page-form__title">
                    Background (1920x400)
                </h3>

                <div class="page-form-control page-form-image">
                    <img [src]="patrocinador.background || 'assets/image-placeholder.png'" alt="background">
                    <button type="button" mat-button (click)="fileInputBackground.click()">Escolher arquivo</button>
                    <input hidden (change)="onFileBackgroundSelected($event.target.files)" #fileInputBackground
                        type="file" id="background" accept="image/*">
                    <button class="page-form-image__button" (click)="removeBackground()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>

                <div class="page-form-control">
                    <mat-slide-toggle (change)="onSituacaoChange($event)"
                        [checked]="patrocinador.situacao == 'A' ? true : false">Situação:
                        {{patrocinador.situacao == 'A' ? 'Ativo' : 'Inativo'}}</mat-slide-toggle>
                </div>

                <hr>

                <button mat-raised-button color="primary" type="submit">
                    SALVAR
                </button>

            </form>
        </div>
    </div>
</section>