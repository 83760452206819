<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Adicionar liga
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" routerLink="/ligas">
                        VOLTAR
                    </button>
                </div>
            </div>
            <form class="page-form" (ngSubmit)="inserirLiga(form)" #form="ngForm">

                <h3 class="page-form__title">
                    Dados da liga
                </h3>

                <div class="page-form-control">
                    <label for="descricao">Palpitei*</label>
                    <mat-checkbox (change)="onCartolaChange($event)" [checked]="liga.cartola == 'S' ? true : false">
                        Palpitei</mat-checkbox>
                </div>

                <mat-form-field class="page-form-control half">
                    <input matInput placeholder="Nome" [(ngModel)]="liga.nome" name="nome" required>
                </mat-form-field>

                <mat-form-field class="page-form-control half">
                    <mat-label>Jogo</mat-label>
                    <mat-select name="jogo" [(ngModel)]="liga.jogo" required>
                        <mat-option *ngFor="let j of jogos" [value]="j">
                            {{j.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <hr>

                <h3 class="page-form__title">
                    Imagem (172x172)
                </h3>

                <div class="page-form-control page-form-image">
                    <img [src]="liga.imagem || 'assets/image-placeholder.png'" alt="imagem">
                    <button type="button" mat-button (click)="fileInput.click()">Escolher arquivo</button>
                    <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file" id="imagem"
                        accept="image/*">
                    <button class="page-form-image__button" (click)="removeImage()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>

                <div class="page-form-control">
                    <mat-slide-toggle (change)="onSituacaoChange($event)"
                        [checked]="liga.situacao == 'A' ? true : false">Situação:
                        {{liga.situacao == 'A' ? 'Ativo' : 'Inativo'}}</mat-slide-toggle>
                </div>

                <hr>

                <button mat-raised-button color="primary" type="submit">
                    SALVAR
                </button>

            </form>
        </div>
    </div>
</section>