<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Adicionar usuário
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" routerLink="/usuarios">
                        VOLTAR
                    </button>
                </div>
            </div>
            <form class="page-form" (ngSubmit)="inserirUsuario(form)" #form="ngForm">

                <h3 class="page-form__title">
                    Dados do usuário
                </h3>

                <div class="page-form-control" *ngIf="usuario.tipoUsuario == 'N'">
                    <label for="descricao">Testa notificação*</label>
                    <mat-checkbox (change)="onTestaNotificacaoChange($event)"
                        [checked]="usuario.testaNotificacao == 'S' ? true : false">Testa notificação</mat-checkbox>
                </div>

                <mat-form-field class="page-form-control">
                    <input matInput type="email" [pattern]="helper.emailRegex" placeholder="E-mail"
                        [(ngModel)]="usuario.email" name="email" required>
                </mat-form-field>

                <mat-form-field class="page-form-control half">
                    <input matInput type="text" placeholder="Nome" [(ngModel)]="usuario.nome" name="nome" required>
                </mat-form-field>

                <mat-form-field class="page-form-control half">
                    <input matInput type="text" placeholder="Sobrenome" [(ngModel)]="usuario.sobrenome" name="sobrenome"
                        required>
                </mat-form-field>

                <mat-form-field class="page-form-control half">
                    <input matInput type="password" placeholder="Senha" [(ngModel)]="usuario.senha" name="senha"
                        required>
                </mat-form-field>

                <mat-form-field class="page-form-control half">
                    <input matInput type="password" placeholder="Confirmar senha" [(ngModel)]="usuario.re_senha"
                        name="re_senha" required>
                </mat-form-field>

                <h3 class="page-form__title">
                    Selecione uma imagem (128x128)
                </h3>

                <div class="page-form-control page-form-image">
                    <img [src]="usuario.imagemPerfil || 'assets/image-placeholder.png'" alt="Usuário">
                    <button type="button" mat-button (click)="fileInput.click()">Escolher arquivo</button>
                    <input hidden (change)="onFileSelected($event.target.files)" #fileInput type="file"
                        id="imagemPerfil" accept="image/*">
                    <button class="page-form-image__button" (click)="removeImage()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>

                <mat-slide-toggle (change)="onSituacaoChange($event)"
                    [checked]="usuario.situacao == 'A' ? true : false">Situação:
                    {{usuario.situacao == 'A' ? 'Ativo' : 'Inativo'}}</mat-slide-toggle>

                <hr>

                <button mat-raised-button color="primary" type="submit">
                    SALVAR
                </button>

            </form>
        </div>
    </div>
</section>