<section class="page">
    <div class="container">
        <div class="page-wrapper">
            <div class="page-header">
                <h1 class="page-title">
                    Adicionar time
                </h1>
                <div class="page-header__buttons">
                    <button mat-stroked-button color="primary" routerLink="/times">
                        VOLTAR
                    </button>
                </div>
            </div>
            <form class="page-form" (ngSubmit)="inserirTime(form)" #form="ngForm">

                <h3 class="page-form__title">
                    Dados do time
                </h3>

                <mat-form-field class="page-form-control two-third">
                    <input matInput type="text" placeholder="Nome" [(ngModel)]="time.nome" name="nome" required>
                </mat-form-field>

                <mat-form-field class="page-form-control third">
                    <input matInput type="tel" placeholder="Ordem" [(ngModel)]="time.ordem" name="ordem" mask="0*"
                        required>
                </mat-form-field>

                <div class="page-form-control">
                    <label for="descricao">Descrição</label>
                    <ckeditor [editor]="helper.editor" [(ngModel)]="time.descricao" data="<p></p>" name="descricao">
                    </ckeditor>
                </div>

                <mat-form-field class="page-form-control">
                    <mat-label>Jogo</mat-label>
                    <mat-select name="jogo" [(ngModel)]="time.jogo" required>
                        <mat-option *ngFor="let j of jogos" [value]="j">
                            {{j.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="page-form-control third">
                    <input matInput type="tel" mask="0*" placeholder="Vitórias" [(ngModel)]="time.vitorias"
                        name="vitorias" required>
                </mat-form-field>

                <mat-form-field class="page-form-control third">
                    <input matInput type="tel" mask="0*" placeholder="Derrotas" [(ngModel)]="time.derrotas"
                        name="derrotas" required>
                </mat-form-field>

                <mat-form-field class="page-form-control third">
                    <input matInput type="tel" mask="0*" placeholder="Campeonatos" [(ngModel)]="time.campeonatos"
                        name="campeonatos" required>
                </mat-form-field>

                <h3 class="page-form__title">
                    Imagem pequena (680x160)
                </h3>

                <div class="page-form-control page-form-image">
                    <img [src]="time.imagemPequena || 'assets/image-placeholder.png'" alt="Jogo">
                    <button type="button" mat-button (click)="fileImagemPequenaInput.click()">Escolher arquivo</button>
                    <input hidden (change)="onFileImagemPequenaSelected($event.target.files)" #fileImagemPequenaInput
                        type="file" id="Jogo imagem" accept="image/*">
                    <button class="page-form-image__button" (click)="removeImagePequena()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>

                <h3 class="page-form__title">
                    Imagem grande (1600x420)
                </h3>

                <div class="page-form-control page-form-image">
                    <img [src]="time.imagemGrande || 'assets/image-placeholder.png'" alt="Jogo">
                    <button type="button" mat-button (click)="fileImagemGrandeInput.click()">Escolher arquivo</button>
                    <input hidden (change)="onFileImagemGrandeSelected($event.target.files)" #fileImagemGrandeInput
                        type="file" id="Jogo ícone" accept="image/*">
                    <button class="page-form-image__button" (click)="removeImageGrande()" type="button">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>

                <div class="page-form-control">
                    <mat-slide-toggle (change)="onSituacaoChange($event)"
                        [checked]="time.situacao == 'A' ? true : false">Situação:
                        {{time.situacao == 'A' ? 'Ativo' : 'Inativo'}}</mat-slide-toggle>
                </div>

                <hr>

                <button mat-raised-button color="primary" type="submit">
                    SALVAR
                </button>

            </form>
        </div>
    </div>
</section>