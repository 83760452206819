import { Subscription } from 'rxjs';
import { SorteioService } from './../../services/class/sorteio.service';
import { Premio } from 'src/app/models/premio.model';
// default
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { HttpProgressEvent } from '@angular/common/http';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Jogo } from 'src/app/models/jogo.model';
import { JogoService, JogosApi } from 'src/app/services/class/jogo.service';
import { Usuario } from 'src/app/models/usuario.model';
import { UsuarioService } from 'src/app/services/class/usuario.service';

@Component({
  selector: 'app-sorteios-editar',
  templateUrl: './sorteios-editar.component.html',
  styleUrls: ['./sorteios-editar.component.scss']
})
export class SorteiosEditarComponent implements OnInit {

  data: Premio = new Premio();

  file: File;
  progress: any;

  buscarSorteioSubscription: Subscription;

  loading: boolean = false;

  jogos: Jogo[] = [];
  buscarJogosSubscription: Subscription;

  searchUsuarios: string = '';
  usuarios: Usuario[] = [];
  buscarUsuariosSubscription: Subscription;

  constructor(
    public helper: HelperService,
    public sorteioService: SorteioService,
    public loadingService: LoadingService,
    public jogoService: JogoService,
    public usuarioService: UsuarioService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    const jogo = new Jogo();
    jogo.id = 0;
    jogo.nome = 'GLOBAL';
    this.jogos.push(jogo);
  }

  ngOnInit() {
    this.buscarSorteioSubscription = this.route.params.subscribe(param => this.buscar(param.id));
    this.buscarJogos();
    this.buscarUsuarios();
  }

  ngOnDestroy() {
    this.buscarSorteioSubscription.unsubscribe();
    this.buscarJogosSubscription.unsubscribe();
    this.buscarUsuariosSubscription.unsubscribe();
  }

  buscarJogos() {
    this.buscarJogosSubscription = this.jogoService.getRanking()
      .subscribe((res: Jogo[]) => this.jogos = this.jogos.concat(res));
  }

  buscar(id) {
    this.loading = true;
    this.sorteioService.getById(id).subscribe((res: Premio) => {
      this.data = res;
      this.loading = false;
    }, e => this.loading = false);
  }

  buscarUsuarios() {
    this.buscarUsuariosSubscription = this.usuarioService.getSelect()
      .subscribe((res: Usuario[]) => this.usuarios = res);
  }

  onFilterUsuarios(ev) {
    if (this.buscarUsuariosSubscription && !this.buscarUsuariosSubscription.closed) {
      this.buscarUsuariosSubscription.unsubscribe();
    }
    if (ev.length > 0) {
      this.buscarUsuariosSubscription = this.usuarioService.getSelect(ev.toLocaleLowerCase())
        .subscribe((res: Usuario[]) => this.usuarios = res);
    } else {
      this.buscarUsuarios();
    }
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando item...');

    this.data.data = this.helper.moment(this.data.data).format('YYYY-MM-DD');

    this.sorteioService.patch(this.data)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/premio/imagem?id=${this.data.id}`);
        this.router.navigate(['/premios']).then(() => {
          this.helper.openSnackBar('Item alterado com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.data.situacao = 'A' : this.data.situacao = 'I';
  }

  onEnviadoChange(event: MatCheckboxChange) {
    event.checked ? this.data.enviado = 'S' : this.data.enviado = 'N';
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.file, `/premio/imagem?id=-99`).then((res: any) => {
      this.data.imagemPremio = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.sorteioService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

  removeImage() {
    this.data.imagemPremio = '';
  }

}
